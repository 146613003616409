import styled from "@emotion/styled";

export const StandardButton = styled.button`
  cursor: pointer;
  color: var(--color-button-text);
  font-size: 16px;
  border-radius: 4px;
  background: var(--color-button-bg);
  border: 0;
  padding: 4px 6px;
  display: block;
  transition-duration: 0.1s;
  :active:not(:disabled) {
    background: var(--color-button-bg-secondary);
  }
  :disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
