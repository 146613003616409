import styled from "@emotion/styled";
import { colors } from "../utils/style";

export const RadioButton = styled.button<{ selected: boolean }>(
  (props) => `
  font-size: 14px;
  border-radius: 4px;
  border: 0;
  background: ${props.selected ? "var(--color-button-bg)" : colors.bg.secondary};
  color: ${props.selected ? colors.bg.primary : colors.text.primary};
  padding: 4px 6px;
  margin-right: 8px;
`,
);
