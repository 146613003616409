import React, { BaseSyntheticEvent, useState } from "react";
import { trackEvent } from "../analytics/analyticsHandlers";
import { appNoteStore } from "../model/services";
import { Note, SerializedNote, SettledNote, noteToSerialized } from "../../shared/types";
import { modelVersion } from "../utils/environment";
import { downloadOrShare } from "../utils/downloadOrShare";
import { ModalEnum, closeModal } from "../model/modals";
import { useAuth } from "../auth/useAuth";
import { addToast } from "../components/Toast";
import logger from "../utils/logger";
import { ApiClient } from "../api/client";
import { ModalContainer } from "./ModalContainer";

enum ExportType {
  JSON = "json",
  TEXT = "text",
}

enum ExportTimeRange {
  LAST_1_DAY = "last-1-day",
  LAST_7_DAYS = "last-7-days",
  ALL = "all",
}

type IdeaflowExport = {
  userId?: string;
  version: string;
  notes: { [key: string]: SerializedNote };
};

function notesToIdeaflowExport(notes: SettledNote[], userId: string): IdeaflowExport {
  return {
    userId,
    version: modelVersion,
    notes: notes.reduce(
      (acc, note) => {
        if (note.authorId === null) {
          note.authorId = userId;
        }
        acc[note.id] = noteToSerialized(note);
        return acc;
      },
      {} as { [key: string]: SerializedNote },
    ),
  };
}

function notesToString(notes: Note[]) {
  return notes.map((note) => appNoteStore.getNoteAsString(note.id)).join("\n--\n");
}

function getDayString() {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  return year + "-" + month + "-" + day;
}

export function ExportModalContent() {
  const { user } = useAuth();

  const [exportType, setExportType] = useState<ExportType>(ExportType.JSON);
  const [exportTimeRange, setExportTimeRange] = useState<ExportTimeRange>(ExportTimeRange.LAST_7_DAYS);
  const [includeDeleted, setIncludeDeleted] = useState(false);

  const handleMediaExport = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const exportImage = e.target[0].checked;
    const exportAudio = e.target[1].checked;
    if ((exportAudio || exportImage) === false) {
      addToast({
        type: "error",
        content: "You must choose at-least one type of media to be exported!",
      });
      return;
    }
    ApiClient()
      .notes.exportMedia(exportImage, exportAudio)
      .then(() => {
        addToast("You will receive your media export on your email soon.");
      })
      .catch((e) => {
        logger.error("Error during media export", { error: e });
        addToast({
          type: "error",
          content: "Oops something went wrong!",
        });
      });
  };

  return (
    <ModalContainer
      type={ModalEnum.EXPORT}
      buttons={[
        {
          text: "Cancel",
          onClick: () => closeModal(ModalEnum.EXPORT),
        },
        {
          text: "Export",
          onClick: () => {
            if (!user) {
              addToast("You must be logged in to export notes");
              logger.error("Missing user when exporting notes");
              return;
            }
            // filter notes by selected time range
            let cutoffDate: Date;
            switch (exportTimeRange) {
              case ExportTimeRange.LAST_1_DAY:
                cutoffDate = new Date(Date.now() - 1000 * 60 * 60 * 24);
                break;
              case ExportTimeRange.LAST_7_DAYS:
                cutoffDate = new Date(Date.now() - 1000 * 60 * 60 * 24 * 7);
                break;
              case ExportTimeRange.ALL:
                cutoffDate = new Date(0);
                break;
            }

            // Settle all positions and select notes matching the specified filters
            const filteredNotes = appNoteStore
              .settle()
              .filter((note) => note.updatedAt > cutoffDate)
              .filter((note) => includeDeleted || !note.deletedAt);

            exportNotes(filteredNotes, user.id, exportType);
            closeModal(ModalEnum.EXPORT);
          },
        },
      ]}
    >
      <h1>Export Notes</h1>
      {/* form with dropdowns for export types and time range  */}
      <form>
        <div style={{ margin: "12px auto" }}>
          <label htmlFor="export-type">File type: </label>
          <select id="export-type" value={exportType} onChange={(e) => setExportType(e.target.value as ExportType)}>
            <option value={ExportType.JSON}>JSON</option>
            <option value={ExportType.TEXT}>Plain text</option>
          </select>
        </div>
        <div style={{ margin: "12px auto" }}>
          <label htmlFor="export-time-range">Filter for: </label>
          <select
            id="export-time-range"
            value={exportTimeRange}
            onChange={(e) => setExportTimeRange(e.target.value as ExportTimeRange)}
          >
            <option value={ExportTimeRange.LAST_1_DAY}>Today's notes</option>
            <option value={ExportTimeRange.LAST_7_DAYS}>Last week of notes</option>
            <option value={ExportTimeRange.ALL}>All notes</option>
          </select>
        </div>
        <div style={{ margin: "12px auto" }}>
          <label htmlFor="export-type">Include deleted? </label>
          <input type="checkbox" checked={includeDeleted} onChange={() => setIncludeDeleted((v) => !v)} />
        </div>
      </form>
      <hr />
      <form onSubmit={handleMediaExport}>
        <input type="checkbox" id="exportImage" name="mediaExport" value="exportImage" />
        <label htmlFor="exportImage">Images</label>&nbsp;
        <input type="checkbox" id="exportAudio" name="mediaExport" value="exportAudio" />
        <label htmlFor="exportAudio">Audios</label>&nbsp;
        <button type="submit">Export Media Separately</button>
      </form>
      <p>
        To ask for more formats or assistance, please{" "}
        <a href="mailto:support@ideaflow.io" className="link" style={{ wordBreak: "unset" }}>
          contact us
        </a>
      </p>
    </ModalContainer>
  );
}

function exportNotes(filteredNotes: SettledNote[], userId: string, exportType: ExportType) {
  const content =
    exportType === ExportType.JSON
      ? JSON.stringify(notesToIdeaflowExport(filteredNotes, userId))
      : notesToString(filteredNotes);
  const filename = `personal-${getDayString()}.if.${exportType === ExportType.JSON ? "json" : "txt"}`;
  const mimeType = exportType === ExportType.JSON ? "application/json" : "text/plain";
  downloadOrShare(new File([content], filename, { type: mimeType }));

  if (exportType === ExportType.JSON) {
    trackEvent("export_to_json");
  } else {
    trackEvent("export_to_plaintext");
  }
}
