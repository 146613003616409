import { DOMParser as PMDOMParser, Fragment } from "prosemirror-model";
import { generateId } from "../../model/generateId";
import {
  TopLevelToken,
  Note,
  NotePosition,
  ParagraphToken,
  isSerializedNote,
  noteToClient,
} from "../../../shared/types";
import { ConflictStrategy } from "../ImportModalContent";
import { getTopLevelTokensFromContent, noteToProsemirrorNode } from "../../editor/bridge";
import { markConflictingEdits } from "../../utils/markConflict";
import { assert } from "../../utils/assert";
import { makeNote } from "../../model/defaults";
import { schema } from "../../editor/schema";
import { embedDepthInformationInHTMLDocument } from "../../editor/utils/clipboard/htmlTools";
import { fixTopLevelTokens } from "../fixTokens";
import { compareNotesPositions } from "../../search/sortUtils";
import { applyOnInline } from "../../../shared/tokenIterators/mapBlockTokens";
import { formatInsertedAt } from "../../editorPage/utils/insertedAt";

interface Mapping {
  [key: string]: string;
}
let mapping: Mapping = {};

export function notesFromMarkdown(
  files: any,
  getPositions: (n: number) => NotePosition[],
): { notes: Note[]; error: { id: string; note: any; error: Error } | null } {
  const finalNotes: Note[] = [];
  let error: { id: string; note: any; error: Error } | null = null;
  mapping = mdFileToIdeaflowLinkMapping(files);
  try {
    files.forEach((file: any, index: number) => {
      const note = mdFileToIdeaflow(file);
      finalNotes.push(note);
      note.position = getPositions(index + 1)[0];
    });
  } catch (e) {
    error = {
      id: "0",
      note: finalNotes,
      error: new Error("Invalid note fields", { cause: e }),
    };
  }
  return { notes: finalNotes, error };
}

export function notesFromPlainText(text: string, getPositions: (n: number) => NotePosition[]): Note[] {
  if (text.trim() === "") return [];
  const notes: Note[] = [];
  const multilineStrings = text.split(/\n(-|—)+\n/gm).filter((l) => !l.match(/^(-|—)+$/));
  const positions = getPositions(multilineStrings.length);
  for (let i = 0; i < multilineStrings.length; i++) {
    const line = multilineStrings[i];
    const tokens = tokenizeMultiline(line);
    notes.push(makeNote({ tokens, position: positions[i] }));
  }
  return notes;
}

export function notesFromExport(data: any, modelVersion: string, userId?: string) {
  assert(data instanceof Object, "Invalid export data");
  assert(data.notes instanceof Object, "Missing notes in export");
  assert(data.version === modelVersion, "Unsupported export version");
  const notes: Note[] = [];
  const errors: { id: string; note: any; error: Error }[] = [];
  Object.entries(data.notes).forEach(([id, noteExport]) => {
    if (!isSerializedNote(noteExport)) {
      errors.push({
        id,
        note: noteExport,
        error: new Error("Invalid note fields"),
      });
      return;
    }
    const note = noteToClient(noteExport);
    try {
      // If the note tokens are invalid, this will throw an error
      noteToProsemirrorNode(note);
    } catch (e) {
      errors.push({
        id,
        note: noteExport,
        error: new Error("Invalid tokens", { cause: e }),
      });
      return;
    }
    notes.push(note);
  });
  return { notes, errors, userId: data.userId };
}

/**
 * Copies the notes from a different user, assigning new ids and positions.
 */
export function copyNotesFromDifferentUser(notes: Note[], generateFirstNPositions: (n: number) => NotePosition[]) {
  const oldToNewIdMap = Object.fromEntries(notes.map((n) => [n.id, generateId()]));
  const positions = generateFirstNPositions(notes.length);
  const date = new Date();
  notes = notes.sort(compareNotesPositions).map((n, i) =>
    makeNote({
      id: oldToNewIdMap[n.id],
      // Position the imported notes at the top, in the order
      // they were when exported.
      position: positions[i],
      tokens: n.tokens,
      createdAt: date,
      updatedAt: date,
      insertedAt: formatInsertedAt(date),
      importForeignId: n.id,
    }),
  );
  // Update the references with the new note ids.
  notes.forEach((note) =>
    applyOnInline(note.tokens, (token) => {
      if (token.type === "spaceship") {
        token.linkedNoteId = oldToNewIdMap[token.linkedNoteId];
      }
    }),
  );
  return notes;
}

export function notesFromAppleNotes(
  files: { text: string; filename: string }[],
  getPositions: (n: number) => NotePosition[],
): Note[] {
  // Process the apple notes html
  let html = "";
  files = files.sort((a, b) => a.filename.localeCompare(b.filename));
  for (const file of files) {
    // Last line is the folder name. Remove it.
    html += `<div class="note">${file.text.trim().split("\n").slice(0, -1).join("\n")}</div>`;
  }
  const doc = new DOMParser().parseFromString(`<div>${html}</div>`, "text/html");
  embedDepthInformationInHTMLDocument(doc);
  doc.querySelectorAll("img").forEach((img) => img.remove());

  // Convert the html to notes
  const node = PMDOMParser.fromSchema(schema).parse(doc.body.childNodes[0]);
  const notes: Note[] = [];
  node.forEach((n) => {
    const tokens = fixTopLevelTokens(getTopLevelTokensFromContent(n.content || new Fragment()));
    notes.push(makeNote({ tokens }));
  });
  const positions = getPositions(notes.length);
  for (let i = 0; i < notes.length; i++) {
    notes[i].position = positions[i];
  }

  return notes;
}

export function handleConflict(importNote: Note, existingNote: Note, strategy: ConflictStrategy) {
  assert(importNote.id === existingNote.id, "Note ids must match");
  if (strategy === ConflictStrategy.Replace) {
    return importNote;
  } else if (strategy === ConflictStrategy.Merge) {
    return {
      ...existingNote,
      tokens:
        JSON.stringify(importNote.tokens) === JSON.stringify(existingNote.tokens)
          ? existingNote.tokens
          : markConflictingEdits(existingNote.tokens, importNote.tokens, "import"),
    };
  } else if (strategy === ConflictStrategy.Ignore) {
    return existingNote;
  } else if (strategy === ConflictStrategy.Duplicate) {
    return { ...importNote, id: generateId() };
  }
  return strategy satisfies never;
}

// Parsing
//
// I had to use different regexp than the rest of the app is using.
const hashtag = /(#[\w\x2d+*]*[A-Za-z_\x2d+*][\w\x2d+*]*)/g;
const link = /\b(https?:\/\/\S+)\b/gi;
const mdLink = /\[([^\]]+)\]\((https?:\/\/\S+)\)/gi;
const todo = /(\[ ?\])/g;
const done = /(\[x\])/g;
const bullet = /^( *[-*] )/g;
const bold = /(\*\*.*?\*\*)/g;
const italic = /(\*.*?\*)/g;
const strikethrough = /(~.*?~)/g;
const underline = /(_.*?_)/g;
const inlineCode = /(`.*?`)/g;
const boldItalic = /(\*\*\*.*?\*\*\*)/g;
const obsidianLinkPattern = /(?:!)?\[\[([^#|\]]+?)[|#]?(?:#([^#|\]]+?))*(?:\|([^#|\]]+?))*\]\]/g;

// Function to tokenize a multiline string
// - Splits the multiline string into individual lines
// - Tokenizes each line using the `tokenizeLine` function
// - Merges adjacent lists if necessary
// - Returns the merged list of tokens representing the entire multiline string
function tokenizeMultiline(multiline: string): TopLevelToken[] {
  const lines = multiline.split("\n").map((l) => tokenizeLine(l));
  // merge adjacent lists
  const merged: TopLevelToken[] = [];
  for (let i = 0; i < lines.length; i++) {
    const curr = lines[i];
    const prev = lines[i - 1];
    if (curr.type === "list" && prev?.type === "list") {
      prev.content.push(...curr.content);
    } else {
      merged.push(curr);
    }
  }
  return merged;
}

function tokenizeLine(line: string): TopLevelToken {
  const unformattedMdLinks = line.replace(mdLink, (_, text, url, whitespace) => {
    //add whitespace if absent to tell Ideaflow the link is terminated
    const finalWhitespace = whitespace ? "" : " ";
    return `${text} ${url}${finalWhitespace}`;
  });
  const splitted = unformattedMdLinks
    .split(bullet)
    .flatMap((s) => {
      // Use match function to find all occurrences of Obsidian link pattern
      const matches = [...s.matchAll(obsidianLinkPattern)];
      if (!matches) {
        return s;
      } else {
        const result: string[] = [];
        let lastIndex = 0;
        // Extract the surrounding text and link target from each match, skipping brackets
        matches.forEach((match) => {
          const index = s.indexOf(match[0], lastIndex);
          // Check if there's any content before the match
          if (index > lastIndex) {
            result.push(s.substring(lastIndex, index));
          }
          result.push("[[" + match[1] + "]]");
          lastIndex = index + match[0].length;
        });
        if (lastIndex < s.length) {
          result.push(s.substring(lastIndex));
        }
        return result.filter((part) => part !== null);
      }
    })
    .flatMap((s) => s.split(hashtag))
    .flatMap((s) => s.split(link))
    .flatMap((s) => s.split(todo))
    .flatMap((s) => s.split(done))
    .flatMap((s) => s.split(boldItalic))
    .flatMap((s) => {
      if (s.startsWith("**") && s.endsWith("**")) {
        return [s];
      } else {
        return s.split(bold);
      }
    })
    .flatMap((s) => {
      if (s.startsWith("**") && s.endsWith("**")) {
        return [s];
      } else {
        return s.split(italic);
      }
    })
    .flatMap((s) => s.split(strikethrough))
    .flatMap((s) => s.split(underline))
    .flatMap((s) => s.split(inlineCode))
    .filter((b) => b !== "");

  const isBullet = splitted[0]?.match(bullet);
  if (isBullet) splitted.shift();

  const content: ParagraphToken = {
    type: "paragraph",
    tokenId: generateId(),
    content: splitted.flatMap((token: string) => {
      if (token.match(hashtag)) {
        return {
          type: "hashtag",
          content: token,
        };
      } else if (token.match(link)) {
        return {
          type: "link",
          content: token,
          slug: token,
        };
      } else if (token.match(todo)) {
        return {
          type: "checkbox",
          isChecked: false,
        };
      } else if (token.match(done)) {
        return {
          type: "checkbox",
          isChecked: true,
        };
      } else if (token.match(boldItalic)) {
        return {
          type: "text",
          content: token.slice(3, -3),
          marks: ["bold", "italic"],
        };
      } else if (token.match(bold)) {
        return {
          type: "text",
          content: token.slice(2, -2),
          marks: ["bold"],
        };
      } else if (token.match(italic)) {
        return {
          type: "text",
          content: token.slice(1, -1),
          marks: ["italic"],
        };
      } else if (token.match(strikethrough)) {
        return {
          type: "text",
          content: token.slice(1, -1),
          marks: ["strikethrough"],
        };
      } else if (token.match(underline)) {
        return {
          type: "text",
          content: token.slice(1, -1),
          marks: ["underline"],
        };
      } else if (token.match(inlineCode)) {
        return {
          type: "text",
          content: token.slice(1, -1),
          marks: ["inlineCode"],
        };
      } else if (token.match(obsidianLinkPattern)) {
        const linkedNote = token.slice(2, -2);
        const linkedNoteId = mapping[linkedNote];
        return {
          type: "spaceship",
          linkedNoteId: linkedNoteId,
          tokenId: generateId(),
        };
      } else {
        return { type: "text", content: token, marks: [] };
      }
    }),
  };

  if (isBullet) {
    return {
      type: "list",
      content: [
        {
          type: "listItem",
          content: [content],
          depth: 0,
        },
      ],
    };
  }
  return content;
}

function mdFileToIdeaflow(file: any): Note {
  const filename = file.filename;
  const content = file.text;
  const title = filename.replace(".md", "").replace("#", "");
  const tokens = tokenizeMultiline(`${title}\n${content}`);
  const note = makeNote({ tokens });
  if (mapping[title]) {
    note.id = mapping[title];
  }
  return note;
}

function mdFileToIdeaflowLinkMapping(files: any): Mapping {
  files.forEach((file: any, index: number) => {
    const title = file.filename.replace(".md", "").replace("#", "");
    const ideaflowId = generateId();
    mapping[title] = ideaflowId;
  });
  return mapping;
}
