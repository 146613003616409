import React from "react";
import styled from "@emotion/styled";
import { colors, mediaQueries, shadows } from "../utils/style";
import { ModalEnum, ModalOptions, closeModal } from "../model/modals";
import { ExportModalContent } from "../export/ExportModalContent";
import { ImportModalContent } from "../export/ImportModalContent";
import { ConfirmDeleteModalContent } from "../export/ConfirmDeleteModalContent";
import { SettingsModalContent } from "../settings/SettingsModalContent";
import { HelpModalContent } from "../help/HelpModalContent";
import { SimonQueryModalContent } from "../utils/SimonQueryModalContent";
import { ResetPasswordModalContent } from "../export/ResetPasswordModalContent";
import UnsyncedLogoutModalContent from "../auth/UnsyncedLogoutModalContent";
import { isMobile } from "../utils/environment";
import PublishDescendantsModal from "../editorPage/noteMenu/PublishDescendantsModal";

const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

/**
 * The modal content floats in the center of the screen on desktop,
 * but takes up the entire screen on mobile.
 *
 * On desktop, the size of the modal content is determined by the
 * width and height of it's children.
 */
export const ModalContainer = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
  border-radius: 8px;
  animation: 300ms;
  animation-name: fadeIn;
  box-shadow: ${shadows.heavy};
  ${mediaQueries.mobile} {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const MobileFullScreenContainer = styled.div`
  width: 80vw;
  max-width: 1000px;
  height: 80vh;
  margin: 0px auto;
  padding: 5px 15px 30px;
  display: flex;
  flex-direction: column;
  ${mediaQueries.mobile} {
    width: 100%;
    height: 100%;
    padding: 5px 10px 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: scroll;
  // smaller padding on mobile
  padding: 10px 30px;
  ${mediaQueries.mobile} {
    padding: 10px;
  }
`;

export function Header({ closeModal }: { closeModal: () => void }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: isMobile ? "flex-start" : "flex-end",
      }}
    >
      {isMobile ? (
        <span
          style={{
            color: colors.text.accent,
            padding: 10,
          }}
          onClick={closeModal}
        >
          ← Back to notes
        </span>
      ) : (
        <div
          style={{
            cursor: "pointer",
            fontSize: 30,
            color: colors.text.secondary,
          }}
          onClick={closeModal}
        >
          ×
        </div>
      )}
    </div>
  );
}

const MODAL_CONTENT: { [key in ModalEnum]: React.ComponentType<object> } = {
  [ModalEnum.DELETE_ACCOUNT]: ConfirmDeleteModalContent,
  [ModalEnum.RESET_PASSWORD]: ResetPasswordModalContent,
  [ModalEnum.EXPORT]: ExportModalContent,
  [ModalEnum.IMPORT]: ImportModalContent,
  [ModalEnum.SETTINGS]: SettingsModalContent,
  [ModalEnum.HELP]: HelpModalContent,
  [ModalEnum.UNSYNCED_LOGOUT]: UnsyncedLogoutModalContent,
  [ModalEnum.PUBLISH_DESCENDANTS]: PublishDescendantsModal,
  [ModalEnum.SIMON_QUERY]: SimonQueryModalContent,
};

export function Modal({ modalName, options }: { modalName: ModalEnum; options?: ModalOptions }) {
  const ContentComponent = MODAL_CONTENT[modalName];

  return (
    <>
      {!options?.noBackdrop && <Background onClick={() => closeModal(modalName)} />}
      {options?.withoutContainer ? (
        <ContentComponent {...options?.customProps} />
      ) : (
        <ModalContainer onClick={(e) => e.stopPropagation()}>
          <ContentComponent {...options?.customProps} />
        </ModalContainer>
      )}
    </>
  );
}
