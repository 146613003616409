import styled from "@emotion/styled";
import { useAtomValue } from "jotai";
import React, { useEffect, useState } from "react";

import { StandardButton } from "../components/StandardButton";
import { colors } from "../utils/style";
import { useUserInfo } from "../model/userInfo";
import { userInfoAtom } from "../model/atoms";

const EditLink = styled.span`
  margin-left: 10px;
  color: ${colors.text.accent};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Input = styled.input`
  display: inline-block;
  border-radius: 4px;
  border: 1px solid ${colors.border.secondary};
  outline: none;
  padding: 6px;
  color: ${colors.text.primary};
  background: ${colors.bg.primary};
  height: 32px;
  :focus-within {
    border: 1px solid ${colors.border.primary};
  }
`;

export function UserHandle() {
  const { changeUserInfo } = useUserInfo();
  const userInfo = useAtomValue(userInfoAtom);

  const [isEditing, setIsEditing] = useState(false);
  const [unsavedHandle, setUnsavedHandle] = useState<string>("");
  const [serverError, setServerError] = useState<string | null>(null);

  useEffect(() => {
    setUnsavedHandle(userInfo?.handle || "");
  }, [userInfo]);

  const onSave = () => {
    if (!unsavedHandle || unsavedHandle === userInfo?.handle) {
      setServerError("No changes to save");
      return;
    }

    changeUserInfo(unsavedHandle)
      .then(() => {
        setServerError(null);
        setIsEditing(false);
      })
      .catch((e: any) => {
        setServerError(e?.message || "Unknown error");
      });
  };

  return (
    <>
      <span style={{ color: colors.text.secondary }}>Handle:</span>&nbsp;
      {!isEditing ? (
        <>
          {userInfo?.handle ? <span>{userInfo.handle}</span> : <i style={{ color: colors.text.tertiary }}>none</i>}
          <EditLink onClick={() => setIsEditing(true)}>{userInfo?.handle ? "Edit" : "Set a handle"}</EditLink>
        </>
      ) : (
        <>
          <Input
            autoFocus={true}
            type="text"
            maxLength={24}
            value={unsavedHandle}
            onChange={(e) => setUnsavedHandle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onSave();
              if (e.key === "Escape") {
                e.stopPropagation();
                setIsEditing(false);
              }
            }}
          />
          <StandardButton
            disabled={!unsavedHandle || unsavedHandle === userInfo?.handle}
            style={{
              display: "inline-block",
              height: 30,
              minWidth: 50,
              marginLeft: 10,
            }}
            onClick={onSave}
          >
            Save
          </StandardButton>
          {serverError && <span style={{ marginLeft: 10, color: colors.text.negative }}>{serverError}</span>}
        </>
      )}
    </>
  );
}
