import { FC, ReactElement } from "react";
import { AccessTokenManager } from "../auth/AccessTokenManager";
import AuthWrapper from "../auth/AuthWrapper";
import { MainLayout } from "../layout";
import { isPersistenceEnabled } from "../utils/environment";
import { useNotesFromFile } from "../model/useNotesFromFile";
import { UserOnboarding } from "./UserOnboarding";

export const EditorWrapper: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <AuthWrapper>
      <MainLayout>
        {isPersistenceEnabled ? (
          <AccessTokenManager>{children}</AccessTokenManager>
        ) : (
          <StaticNoteLoader>{children}</StaticNoteLoader>
        )}
        <UserOnboarding />
      </MainLayout>
    </AuthWrapper>
  );
};

const StaticNoteLoader = ({ children }: { children: React.ReactElement }) => {
  useNotesFromFile();
  return children;
};
