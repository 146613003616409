import { useEffect, useRef } from "react";
import { Env, env, isRunningInIOSWebview, publicVersion } from "../utils/environment";
import { addToast } from "../components/Toast";
import logger from "../utils/logger";
import { APP_STORE_URL } from "../utils/constants";
import { StaticApiClient } from "../api/client";

/** Convert a semantic version to a number for comparison */
function toNum(version: string): number {
  try {
    const parts = version.split(".").map((n) => parseInt(n) || 0);
    let res = 0;
    for (let i = 0; i < parts.length; i++) {
      res += parts[i] * Math.pow(10, 2 * (parts.length - i));
    }
    return res;
  } catch (e) {
    logger.error("Error converting version to number", { error: e });
    return 0;
  }
}

/**
 * Prompt the user to update the app if the client is not the latest version.
 * Only runs once per session.
 */
export const usePromptUserToUpdateApp = (accessToken: string | null) => {
  const hasRun = useRef(false);
  useEffect(() => {
    if (hasRun.current || !accessToken) return;
    if (env === Env.local || env === Env.preview) return;
    hasRun.current = true;
    promptUserToUpdateApp(accessToken);
  }, [accessToken]);
};

async function promptUserToUpdateApp(accessToken: string) {
  // Get the latest app versions deployed.
  const client = StaticApiClient(accessToken);

  const versions = await client.versions.get();

  const latestWebVersion = versions.webVersion;
  const latestAppStoreVersion = versions.appStoreVersion;

  // Prompt the user to update if the client is not the latest version
  if (isRunningInIOSWebview) {
    logger.info(`Client is v${publicVersion} and latest is v${latestAppStoreVersion}`, { namespace: "main" });
    if (toNum(latestAppStoreVersion) > toNum(publicVersion ?? "")) {
      logger.warn("Outdated iOS app", {
        report: true,
        context: { publicVersion, latestAppStoreVersion },
      });
      addToast({
        content: "New version available. Please download the latest version from the App Store",
        buttons: [
          {
            text: "Download",
            href: APP_STORE_URL,
          },
        ],
      });
    }
  } else {
    logger.info(`Client is v${publicVersion} and latest is v${latestWebVersion}`, { namespace: "main" });
    if (toNum(latestWebVersion) > toNum(publicVersion ?? "")) {
      logger.warn("Outdated web app", {
        report: true,
        context: { publicVersion, latestWebVersion },
      });
      addToast({
        content: "New version available. Please refresh your browser",
        buttons: [
          {
            text: "Refresh",
            onClick: () => window.location.reload(),
          },
        ],
      });
    }
  }
}
