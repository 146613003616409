import styled from "@emotion/styled";
import { Download } from "react-feather";
import { useAtom } from "jotai";
import { isIOs, isMobile, isRunningInIOSWebview } from "../utils/environment";
import { APP_STORE_URL } from "../utils/constants";
import { pwaEventAtom } from "./InstallBanner";

const isIOS = isIOs || (typeof navigator !== "undefined" && /(Mac).*WebKit/.test(navigator.userAgent));

export const SettingsMenuAppInstallButton = () => {
  const [pwaEvent, setPwaEvent] = useAtom(pwaEventAtom);

  if (isIOS && !isRunningInIOSWebview && isMobile) {
    return <InstallButton installButtonText="Install App" installButtonCallback={() => window.open(APP_STORE_URL)} />;
  }

  if (pwaEvent) {
    return (
      <InstallButton
        installButtonText="Install App"
        installButtonCallback={() => {
          pwaEvent.prompt();
          setPwaEvent(null);
        }}
      />
    );
  }

  return null;
};

const InstallButton = ({
  installButtonText,
  installButtonCallback,
}: {
  installButtonText: string;
  installButtonCallback: () => void;
}) => {
  return (
    <InstallButtonContainer onClick={installButtonCallback}>
      <span
        style={{
          marginRight: "5px",
          color: "#fff",
        }}
      >
        {installButtonText}
      </span>

      <Download width={"1.5rem"} height={"1.5rem"} color={"#fff"} />
    </InstallButtonContainer>
  );
};

const InstallButtonContainer = styled.button`
  padding: 0.625rem 1.25rem;
  border-radius: 50px;
  max-height: 50px;
  align-items: center;
  text-align: center;
  display: inline-flex;
  border-width: 0;
  border-style: solid;
  margin-right: 10px;
  background-color: var(--color-bg-accent-primary);
  &:hover {
    opacity: 0.8;
  }
  > span {
    white-space: nowrap;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    display: grid;
    align-items: center;
    color: #fff;
    font-family: "Inter", sans-serif;
  }
`;
