import { z } from "zod";

// ts-prune-ignore-next
export const NoteIdSchema = z.string();
const TokenIdSchema = z.string();
const FolderIdSchema = z.string();
const IdeaDateSchema = z.date();

export type NoteId = z.infer<typeof NoteIdSchema>;
export type FolderId = z.infer<typeof FolderIdSchema>;
export type HashtagId = string;
export type IdeaDate = z.infer<typeof IdeaDateSchema>;
export type TokenId = z.infer<typeof TokenIdSchema>;
type ImageSrc = string;

export type UserInfo = {
  handle: string | null;
};

/**
 * Index of block token (not counting bullet list tokens) inside a {@link Note}.
 */
const SecondaryMarkSchema = z.enum(["bold", "italic", "underline", "strikethrough", "inlineCode"]);
export type SecondaryMark = z.infer<typeof SecondaryMarkSchema>;

const TextTokenSchema = z.object({
  type: z.literal("text"),
  marks: z.array(SecondaryMarkSchema),
  content: z.string(),
});

export type TextToken = z.infer<typeof TextTokenSchema>;

const LinkTokenSchema = z.object({
  type: z.literal("link"),
  content: z.string(),
  slug: z.string(),
});

type LinkToken = z.infer<typeof LinkTokenSchema>;

const HashtagTokenSchema = z.object({
  type: z.literal("hashtag"),
  content: z.string(),
});

type HashtagToken = z.infer<typeof HashtagTokenSchema>;
/**
 * Reference token.
 *
 * These used to be called spaceships, which is why the type is "spaceship".
 * When we changed the name, we kept the type to avoid dealing with migration
 * issues. Migrating old tokens on new clients is easy, but we don't currently
 * have a mechnism to migrate new data back to old clients (e.g. if we change the
 * type to "reference", and then an old client receives one of these tokens, it
 * won't know what to do with it).
 */

const ReferenceTokenSchema = z.object({
  type: z.literal("spaceship"),
  linkedNoteId: NoteIdSchema,
  tokenId: TokenIdSchema,
});

type ReferenceToken = z.infer<typeof ReferenceTokenSchema>;

// The image tokens inherited from the older versions of the app
// might not have some properties present
const ImageTokenSchema = z.object({
  type: z.literal("image"),
  src: z.string(),
  width: z.number().nullable(),
  naturalWidth: z.number().nullable(),
  naturalHeight: z.number().nullable(),
  smallPreviewDataURL: z.string().nullable(),
});

export type ImageToken = z.infer<typeof ImageTokenSchema>;

const AsyncReplacedElementSchema = z.object({
  type: z.literal("asyncReplacedElement"),
  operationId: z.string(),
  operationStartTimestamp: z.number(),
  fallbackText: z.string(),
});

type AsyncReplacedElement = z.infer<typeof AsyncReplacedElementSchema>;

const CheckboxTokenSchema = z.object({
  type: z.literal("checkbox"),
  isChecked: z.boolean(),
});

type CheckboxToken = z.infer<typeof CheckboxTokenSchema>;

const IdeaPositionSchema = z.string();
export type IdeaPosition = z.infer<typeof IdeaPositionSchema>;

const AudioInsertTokenSchema = z.object({
  type: z.literal("audioInsert"),
  tokenId: z.string(),
  audioId: z.string(),
  chunkCount: z.number().nullable(),
  durations: z.array(z.number()).nullable(),
  updatedAt: z.number(),
  transcript: z.string().nullable(),
  transcriptLanguageOverride: z.string().nullable(),
  transcriptGenId: z.string().nullable(),
  isRevealed: z.boolean(),
});

export type AudioInsertToken = z.infer<typeof AudioInsertTokenSchema>;

const CodeblockTokenSchema = z.object({
  tokenId: TokenIdSchema,
  type: z.literal("codeblock"),
  content: z.array(TextTokenSchema),
});

export type CodeblockToken = z.infer<typeof CodeblockTokenSchema>;

const LinkLoaderTokenSchema = z.object({
  type: z.literal("linkloader"),
  tokenId: z.string(),
  url: z.string(),
  isActive: z.boolean(),
});

type LinkLoaderToken = z.infer<typeof LinkLoaderTokenSchema>;

const InlineTokenSchema = z.union([
  LinkTokenSchema,
  ImageTokenSchema,
  AsyncReplacedElementSchema,
  HashtagTokenSchema,
  TextTokenSchema,
  ReferenceTokenSchema,
  CheckboxTokenSchema,
  LinkLoaderTokenSchema,
]);

export type InlineToken = z.infer<typeof InlineTokenSchema>;

const ParagraphTokenSchema = z.object({
  tokenId: TokenIdSchema,
  type: z.literal("paragraph"),
  content: z.array(InlineTokenSchema),
  depth: z.number().optional(),
});

export type ParagraphToken = z.infer<typeof ParagraphTokenSchema>;

const BlockContentTokenSchema = z.union([ParagraphTokenSchema, CodeblockTokenSchema, AudioInsertTokenSchema]);
export type BlockContentToken = z.infer<typeof BlockContentTokenSchema>;

const ListItemTokenSchema = z.object({
  type: z.literal("listItem"),
  content: z.array(BlockContentTokenSchema),
  depth: z.number(),
});

export type ListItemToken = z.infer<typeof ListItemTokenSchema>;

const ListTokenSchema = z.object({
  type: z.literal("list"),
  content: z.array(ListItemTokenSchema),
});

export type ListToken = z.infer<typeof ListTokenSchema>;

/** Top level tokens can exist one level of depth below Note type */
const TopLevelTokenSchema = z.union([
  ParagraphTokenSchema,
  CodeblockTokenSchema,
  ListTokenSchema,
  AudioInsertTokenSchema,
]);

export type TopLevelToken = z.infer<typeof TopLevelTokenSchema>;

const PersistableSchema = z.object({
  id: z.string(),
  updatedAt: z.date(),
  deletedAt: z.date().nullable(),
});

// TypeScript type inference from Zod schema
export type Persistable = z.infer<typeof PersistableSchema>;

const NotePositionSchema = z.union([
  z.object({
    type: z.literal("settled"),
    position: IdeaPositionSchema,
  }),
  z.object({
    type: z.literal("on-top-provisional"),
    position: IdeaPositionSchema,
  }),
]);
export type NotePosition = z.infer<typeof NotePositionSchema>;

// ts-prune-ignore-next
export const NoteSchema = PersistableSchema.extend({
  id: NoteIdSchema,
  authorId: z.string().nullable(),
  tokens: z.array(TopLevelTokenSchema),
  createdAt: IdeaDateSchema,
  updatedAt: IdeaDateSchema,
  deletedAt: IdeaDateSchema.nullable(),
  insertedAt: z.string(),
  position: NotePositionSchema,
  readAll: z.boolean(),
  isSharedPrivately: z.boolean(),
  directUrlOnly: z.boolean().optional(), // deprecated
  folderId: z.string().nullable(),
  positionInPinned: z.string().nullable().optional(),
  importSource: z.string().nullable().optional(),
  importBatch: z.string().nullable().optional(),
  importForeignId: z.string().nullable().optional(),
  expansionSetting: z.enum(["auto", "expand", "collapse"]).optional(),
  simonHash: z.string().nullable().optional(),
  simonUpdatedAt: z.date().nullable().optional(),
});

export const NoteWithPositionSchema = NoteSchema.extend({
  position: z.string(),
});

// TypeScript type inference from Zod schema
export type Note = z.infer<typeof NoteSchema>;

export type NoteWithPosition = z.infer<typeof NoteWithPositionSchema>;

export type SettledNote = Note & {
  position: { type: "settled"; position: IdeaPosition };
};

export interface SerializedNote {
  id: NoteId;
  author_id: string;
  created_at: string;
  deleted_at: string | null;
  inserted_at: string; // "20210323"
  position: string;
  tokens: TopLevelToken[];
  read_all: boolean; // true if the note is published to user's public feed.
  is_shared_privately: boolean; // true if sharing link has been created for tbe note
  direct_url_only?: boolean; // deprecated
  updated_at: string; //"2021-03-24T00:07:56.075283+00:00"
  position_in_pinned: string | null;
  folder_id: string | null;
  import_source: string | null;
  import_batch: string | null;
  import_foreign_id: string | null;
  expansion_setting: "auto" | "expand" | "collapse" | null;
  __typename?: string;
}

// ts-prune-ignore-next
export type SerializedFolder = {
  id: FolderId;
  name: string;
  parent_id: string | null;
  position: IdeaPosition;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  __typename?: string;
};

export const noteToSerialized = (note: SettledNote): SerializedNote => {
  return {
    id: note.id,
    author_id: note.authorId || "",
    created_at: note.createdAt.toISOString(),
    deleted_at: note.deletedAt?.toISOString() || null,
    inserted_at: note.insertedAt,
    position: note.position.position,
    tokens: note.tokens,
    read_all: note.readAll,
    is_shared_privately: note.isSharedPrivately ?? false,
    direct_url_only: note.directUrlOnly,
    updated_at: note.updatedAt.toISOString(),
    position_in_pinned: note.positionInPinned ?? null,
    folder_id: note.folderId ?? null,
    import_source: note.importSource ?? null,
    import_batch: note.importBatch ?? null,
    import_foreign_id: note.importForeignId ?? null,
    expansion_setting: note.expansionSetting ?? null,
  };
};

export const noteToClient = (note: SerializedNote): Note => {
  return {
    id: note.id,
    authorId: note.author_id,
    createdAt: toDate(note.created_at),
    updatedAt: toDate(note.updated_at),
    deletedAt: note.deleted_at ? toDate(note.deleted_at) : null,
    tokens: note.tokens,
    insertedAt: note.inserted_at,
    position: { type: "settled", position: note.position },
    readAll: note.read_all,
    isSharedPrivately: note.is_shared_privately,
    directUrlOnly: note.direct_url_only,
    positionInPinned: note.position_in_pinned,
    folderId: note.folder_id,
    importSource: note.import_source,
    importBatch: note.import_batch,
    importForeignId: note.import_foreign_id,
    expansionSetting: note.expansion_setting ?? "auto",
  };
};

export function isSerializedNote(v: any): v is SerializedNote {
  return (
    v instanceof Object &&
    typeof v.id === "string" &&
    typeof v.created_at === "string" &&
    typeof v.updated_at === "string" &&
    (v.deleted_at === null || typeof v.deleted_at === "string") &&
    typeof v.inserted_at === "string" &&
    typeof v.position === "string" &&
    Array.isArray(v.tokens) &&
    typeof v.read_all === "boolean" &&
    (v.position_in_pinned === null || typeof v.position_in_pinned === "string") &&
    (v.folder_id === null || typeof v.folder_id === "string") &&
    (v.import_source === null || typeof v.import_source === "string") &&
    (v.import_batch === null || typeof v.import_batch === "string") &&
    (v.import_foreign_id === null || typeof v.import_foreign_id === "string")
  );
}

// ts-prune-ignore-next
export const FolderSchema = PersistableSchema.extend({
  id: FolderIdSchema,
  name: z.string(),
  parentId: FolderIdSchema.nullable(),
  position: IdeaPositionSchema,
  createdAt: IdeaDateSchema,
  updatedAt: IdeaDateSchema,
  deletedAt: IdeaDateSchema.nullable(),
});

export type Folder = z.infer<typeof FolderSchema>;

export const folderToClient = (folder: SerializedFolder): Folder => {
  return {
    id: folder.id,
    name: folder.name,
    parentId: folder.parent_id,
    position: folder.position,
    updatedAt: toDate(folder.updated_at),
    createdAt: toDate(folder.created_at),
    deletedAt: folder.deleted_at ? toDate(folder.deleted_at) : null,
  };
};

export interface Hashtag {
  content: string;
}

const toDate = (ts: string) => {
  const d = new Date(ts);
  if (d instanceof Date && !isNaN(d as any)) return d;
  return new Date();
};

type NonCustomizableShortcutKeys = [
  "splitNote",
  "openAsPage",
  "searchForSelection",
  "insertParagraph",
  "insertBr",
  "tab",
  "shiftTab",
  "selectAll",
  "undo",
  "redo",
  "goToLineStart",
  "goToLineEnd",
  "goToNoteStart",
  "goToNoteEnd",
  "collapseReference",
  "expandReference",
  "collapseBacklinks",
  "expandBacklinks",
  "selectUntilNoteStart",
  "selectUntilNoteEnd",
  "createNewNoteFromSearch",
  "toggleItalics",
  "toggleBold",
  "toggleUnderline",
  "clearFormatting",
  "toggleCheckboxShortcut",
  "toggleStrikethrough",
  "backspace",
  "delete",
  "jumpTo",
  "plus",
  "hashtag",
  "insertCodeblock",
  "savePage",
];

export type CustomizableShortcutKeys = [
  "createNewNote",
  "toggleRecording",
  "createNewPage",
  "search",
  "toggleSidebar",
  "backToAllNotes",
  "toggleSettings",
  "toggleHelp",
  "toggleCheckboxKey",
  "extractEntitiesWithAI",
  "openTodos",
];

type DevShortcutKeys = ["dev-only-logout"];

export type Keybinding = string;

type RawShortcut<StringId extends string, IsCustomizable = boolean> = {
  id: StringId;
  // Human-readable description for the shortcut that appears in the UI
  // If not defined, it won't show up in the shortcuts list
  label?: string;
  // These names use https://github.com/marijnh/w3c-keyname/blob/master/index.es.js convention
  // we use a partial implementation of this naming convention in
  // utils.useHotkeys for all non prosemirror related usages.
  keys: Keybinding;
  // If the binding displayed in the option needs to be different from the
  // actual binding. Used for macOS, where the Option key will sometimes insert
  // non-ASCII Unicode chars.
  keysForShortcutList?: Keybinding;
  // Can be customized by the user
  isCustomizable: IsCustomizable;
};

export type NonCustomizableShortcut = RawShortcut<NonCustomizableShortcutKeys[number], false>;
export type CustomizableShortcut = RawShortcut<CustomizableShortcutKeys[number], true>;
export type Shortcut = CustomizableShortcut | NonCustomizableShortcut;

export type NonCustomizableShortcuts = Record<NonCustomizableShortcutKeys[number], NonCustomizableShortcut>;
export type CustomizableShortcuts = Record<CustomizableShortcutKeys[number], CustomizableShortcut>;
export type Shortcuts = CustomizableShortcuts & NonCustomizableShortcuts;

export type DevShortcut = RawShortcut<DevShortcutKeys[number], false>;

export type PinnedHashtag = { id: string; positionInPinned: string; type: "hashtag" };
export interface PinnedItem {
  id: string;
  positionInPinned: string | null;
  type: "note" | "hashtag";
}

/**
 * User settings.
 *
 * The `seen` properties are used to track whether the user has seen a certain
 * feature.
 *
 * @warning If you update this, update {@link isUserSettings} as well.
 */
export type UserSettings = {
  seenAtSignToast: boolean;
  seenTildeSignToast: boolean;
  seenOcrToast: boolean;
  unfurlingEnabled: boolean;
  smartAutocompleteEnabled: boolean;
  commaAutocompleteEnabled: boolean;
  atSignHashtagEnabled: boolean;
  tildeSignHashtagEnabled: boolean;
  ignoreHashtagAutocorrect: boolean;
  seenOpenAsPageHint: boolean;
  seenToDoToast: boolean;
  seenUnfurl: boolean;
  seenUnfurlUndo: boolean;
  // seenExportNotesOnSaveToast: boolean;
  // Use to permanently dismiss export notes on save toast
  seenOnboarding: boolean;
  imageOcrEnabled: boolean;
  appendExtractsToCanonicalNotes: boolean;
  deleteWarningThreshold: number;
  showTodoStrikethrough: boolean;
  defaultTranscriptionLanguage: string;
  customShortcuts: Partial<CustomizableShortcuts>;
  pinnedHashtags: PinnedHashtag[];
  simonSearchEnabled: boolean;
};

export function isUserSettings(v: any): v is UserSettings {
  return (
    v instanceof Object &&
    typeof v.unfurlingEnabled === "boolean" &&
    typeof v.smartAutocompleteEnabled === "boolean" &&
    typeof v.atSignHashtagEnabled === "boolean" &&
    typeof v.tildeSignHashtagEnabled === "boolean" &&
    typeof v.ignoreHashtagAutocorrect === "boolean" &&
    typeof v.seenOpenAsPageHint === "boolean" &&
    typeof v.seenToDoToast === "boolean" &&
    typeof v.seenOcrToast === "boolean" &&
    typeof v.imageOcrEnabled === "boolean" &&
    typeof v.commaAutocompleteEnabled === "boolean" &&
    typeof v.seenUnfurl === "boolean" &&
    typeof v.seenUnfurlUndo === "boolean" &&
    // typeof v.seenExportNotesOnSaveToast === "boolean" &&
    typeof v.seenOnboarding === "boolean" &&
    typeof v.appendExtractsToCanonicalNotes === "boolean" &&
    typeof v.deleteWarningThreshold === "number" &&
    typeof v.showTodoStrikethrough === "boolean" &&
    typeof v.defaultTranscriptionLanguage === "string" &&
    typeof v.customShortcuts === "object" &&
    typeof v.pinnedHashtags === "object" &&
    typeof v.simonSearchEnabled === "boolean"
  );
}

export type PublicNoteResponse = {
  data: {
    note: SerializedNote;
    linkedNotePreviews: { id: string; text: string }[];
    publicNotes: SerializedNote[];
  } | null;
  error: string | null;
};

export type UserPublicNotesResponse = {
  data: {
    notes: SerializedNote[];
    linkedNotePreviews: { id: string; text: string }[];
  } | null;
  error: string | null;
};

export type TranscriptionResponse = {
  error: string | null;
  data: string | null;
};

export type DeltaResponse = {
  data: {
    user: {
      id: string;
      email: string;
      settings: { [key: string]: any };
    } | null;
    note: SerializedNote[];
    folder: SerializedFolder[];
  } | null;
  error: string | null;
};

export type UpsertFolderResponse = {
  data: {
    id: string;
    updated_at: string;
    deleted_at: string | null;
  } | null;
  error: string | null;
};

export type BatchNotesUpsertResponse = {
  data: {
    id: string;
    updated_at: string;
    deleted_at: string | null;
    position: string;
  }[];
  error: string | null;
};
