import { useEffect } from "react";
import { useSetAtom } from "jotai";
import { getDefaultNotes } from "../service-worker/create-account/defaultNotes";
import { useUpdateEditor } from "../editorPage/atomHelpers/editorUpdate";
import { useNotifySidebarUpdate } from "../sidebar/atoms/sidebarUpdate";
import { isLoadedAtom, userSettingsAtom } from "./atoms";
import { makeUserSettings } from "./defaults";
import { appFolderStore, appNoteStore } from "./services";

export const useNotesFromFile = () => {
  const notifyEditorUpdate = useUpdateEditor();
  const notifySidebarUpdate = useNotifySidebarUpdate();
  const setUserSettings = useSetAtom(userSettingsAtom);

  const setIsLoaded = useSetAtom(isLoadedAtom);
  useEffect(() => {
    const { notes, folders } = getDefaultNotes("google-oauth2|112556675592475315585");
    appFolderStore.upsert(folders, true);
    appNoteStore.upsert(notes, true);
    setUserSettings(makeUserSettings());
    notifyEditorUpdate();
    notifySidebarUpdate();
    setIsLoaded(true);
  }, [notifyEditorUpdate, notifySidebarUpdate, setIsLoaded, setUserSettings]);
};
