export const promiseWithTimeout = (ms: number, callback: () => Promise<unknown>) => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject("Callback timed out");
    }, ms);

    callback()
      .then((response) => resolve(response))
      .catch((error) => reject(error))
      .finally(() => {
        clearTimeout(timeout);
      });
  });
};
