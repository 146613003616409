import styled from "@emotion/styled";
import { useAtomValue } from "jotai";
import { colors } from "../utils/style";
import { upsertUserSettings } from "../model/userSettings";
import { SelectMenu } from "../components/SelectMenu";
import { TRANSCRIPTION_LANGUAGES } from "../../shared/transcription";
import { userSettingsAtom } from "../model/atoms";

const LabelOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  p {
    font-size: 14px;
    color: ${colors.text.secondary};
  }
`;

export const DefaultTranscriptionLanguage = () => {
  const curSetting = useAtomValue(userSettingsAtom).defaultTranscriptionLanguage;
  const options = Object.entries(TRANSCRIPTION_LANGUAGES).map(([lang, label]) => ({
    value: lang,
    label: label,
  }));

  return (
    <label style={{ display: "block", marginBottom: 12 }}>
      <div>Default transcription language</div>
      <LabelOptionWrapper>
        <p>Set the default language for audio transcriptions.</p>
        <SelectMenu
          label="Language"
          options={options}
          currentValue={curSetting}
          setValue={(lang) => {
            upsertUserSettings({ defaultTranscriptionLanguage: lang });
          }}
        />
      </LabelOptionWrapper>
    </label>
  );
};
