import React, { useState } from "react";
import styled from "@emotion/styled";
import { Spinner } from "../utils/Spinner";
import { useLogoutAllTabs } from "../auth/Logout";
import { colors } from "../utils/style";
import { ModalEnum, closeModal, lockModal, unlockModal } from "../model/modals";
import { appLocalStore } from "../model/services";
import { ModalContainer } from "./ModalContainer";

const Input = styled.input`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${colors.border.secondary};
  background: ${colors.bg.tertiary};
  height: 32px;
  :focus-within {
    border: 1px solid ${colors.border.primary};
  }
`;

/**
 * After pressing the delete button, a modal appears asking the user to confirm
 * that they want to delete the note.
 *
 */
export function ConfirmDeleteModalContent() {
  const [stage, setStage] = useState<"confirm" | "deleting" | "failed">("confirm");
  const [showError, setShowError] = useState(false);
  const { logoutAllTabs } = useLogoutAllTabs();
  const [input, setInput] = useState("");

  const handleSubmit = async () => {
    if (input !== "DELETE") return setShowError(true);
    setShowError(false); // Hide error if it was previously shown
    setStage("deleting");
    lockModal(ModalEnum.DELETE_ACCOUNT);
    const deleted = await appLocalStore.sendDeleteAccountAndAwaitAck();
    if (deleted) {
      await logoutAllTabs();
    } else {
      setStage("failed");
    }
    unlockModal(ModalEnum.DELETE_ACCOUNT);
  };

  return (
    <ModalContainer
      type={ModalEnum.DELETE_ACCOUNT}
      buttons={
        stage === "confirm"
          ? [
              {
                text: "Confirm",
                onClick: handleSubmit,
              },
              {
                text: "Cancel",
                onClick: () => closeModal(ModalEnum.DELETE_ACCOUNT),
              },
            ]
          : []
      }
    >
      <div
        style={{
          maxWidth: 400,
          paddingBottom: 20,
        }}
      >
        <h1>Delete account</h1>
        {stage === "confirm" ? (
          <div>
            <p>Are you sure you sure? This will delete your account, including all your notes, and cannot be undone.</p>
            <p>To confirm, type DELETE in the box below, then click confirm.</p>
            <form onSubmit={handleSubmit}>
              <Input
                type="text"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  setShowError(false);
                }}
              />
              {showError && <div>Please enter 'DELETE' to confirm.</div>}
            </form>
          </div>
        ) : stage === "deleting" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Spinner style={{ width: 30, height: 30, margin: 5 }} />
            <p>Deleting your account...</p>
          </div>
        ) : stage === "failed" ? (
          <>
            <p>Something went wrong and we couldn't delete your account...</p>
            <p>
              <span>Please contact us at </span>
              <a href="mailto:support@ideaflow.io" target="_blank" rel="noreferrer" className="link">
                support@ideaflow.io
              </a>
              <span> for assistance.</span>
            </p>
          </>
        ) : null}
      </div>
    </ModalContainer>
  );
}
