import React from "react";
import styled from "@emotion/styled";
import { redirectUri, isMac, isRunningInIOSWebview } from "../utils/environment";
import logger from "../utils/logger";

import { useAuth } from "./useAuth";

const Button = styled.button`
  unset: all;
`;

const ButtonLink = styled.a`
  color: var(--color-text-primary);
  background-color: none;
  text-decoration: underline;
`;

interface LoginButtonProps {
  className?: string;
  autoFocus?: boolean;
}

const LoginButton: React.FC<LoginButtonProps> = ({ className, autoFocus }) => {
  const { isLoading, user, isAuthenticated, loginWithRedirect, logout, error } = useAuth();

  if (isLoading) {
    return <div>Loading your profile information...</div>;
  }

  if (error) {
    logout();
  }

  // we cannot be authenticated and have no user
  if (isAuthenticated && !user) {
    return (
      <div>
        Oops! We encountered a problem authenticating you. Please refresh by pressing {isMac ? " ⌘ + r" : "ctrl  +r"}
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Button
        className={className}
        autoFocus
        onClick={() => {
          try {
            // We redirect to the default URI when running with the `isRunningInIOSWebview` flag but actually outside of the WkWebView
            // This makes it possible to test the `isRunningInIOSWebview` mode in a single browser tab
            loginWithRedirect(
              isRunningInIOSWebview && window.location.protocol === "thoughtstream-app:" ? { redirectUri } : undefined,
            );
          } catch (e) {
            logger.error(e);
          }
        }}
      >
        Log in
      </Button>
    );
  }

  return null;
};

export function PrivacyPolicyButton() {
  return (
    <ButtonLink href="https://www.ideaflow.io/privacy-policy" target="_blank">
      Privacy Policy
    </ButtonLink>
  );
}

export default LoginButton;
