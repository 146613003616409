import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { appNoteStore } from "../model/services";
import { colors } from "../utils/style";

const MS_IN_A_DAY = 86400 * 1000;

const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const DaySquare = styled.div`
  background-color: ${colors.text.accent};
  width: 100%;
  height: 100%;
  border-radius: 2px;
`;

const DaySquareContainer = styled.div`
  border-radius: 2px;
  flex-shrink: 0;
  width: 14px;
  height: 14px;
  margin: 2px;
  position: relative;
  cursor: cell;

  ::after {
    content: attr(aria-label);
    display: block;
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    text-align: left;
    color: ${colors.text.primary};
    white-space: pre;
    opacity: 0;
    background-color: ${colors.bg.primary};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 12px;
    pointer-events: none;
    z-index: 100;
    transition: opacity 0.2s;
  }

  &[data-tooltip-direction="left"]::after {
    left: unset;
    right: 0;
    text-align: right;
  }

  :hover::after {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 126px;
  margin-top: 28px;
  user-select: none;
`;

const ScrollContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

type NoteCount = {
  count: number;
  date: Date;
};

/**
 * Clips the left side of the chart which overflows so that there's a smooth
 * gradient out rather than a harsh cutoff. We do not hide overflow so tooltips
 * can overflow outside the chart.
 */
const StreakChartCover = () => {
  return (
    <div
      style={{
        position: "absolute",
        right: "calc(100% - 80px)",
        top: -30, // to cover month markers
        bottom: 0,
        background: `linear-gradient(to left, ${colors.transparent}, ${colors.bg.primary} 10%)`,
        width: 500,
        pointerEvents: "none",
        fontSize: 12,
      }}
    >
      <div style={{ position: "absolute", right: 50 }}>
        <div style={{ marginTop: 48 }}>Mon</div>
        <div style={{ marginTop: 21 }}>Wed</div>
        <div style={{ marginTop: 20 }}>Fri</div>
      </div>
    </div>
  );
};

export const StreakChart = () => {
  const [noteCreationDates, setNoteCreationDates] = useState<Date[]>([]);

  useEffect(() => {
    const dates = appNoteStore
      .getAll()
      .map((note) => note.createdAt)
      .sort();
    // Because rendering the chart is costly, we first let the settings page
    // render with a loader and wait for that to paint before re-rendering
    // with real data
    setTimeout(() => {
      setNoteCreationDates(dates);
    }, 30);
  }, []);

  const now = new Date().getTime();
  const startOfWeek = now - MS_IN_A_DAY * new Date().getDay();
  const endOfWeek = startOfWeek + MS_IN_A_DAY * 7;
  const Weeks52Ago = startOfWeek - MS_IN_A_DAY * 7 * 50;
  const middleDate = (Weeks52Ago + now) / 2;

  const [notesAddedByWeek, maxNotesAddedDaily] = useMemo(() => {
    const notesAddedByDay: NoteCount[] = [];
    for (let startOfDay = Weeks52Ago; startOfDay < endOfWeek; startOfDay += MS_IN_A_DAY) {
      const notesAddedThisDay = noteCreationDates.filter(
        (createdAt) => +createdAt > startOfDay && +createdAt < startOfDay + MS_IN_A_DAY,
      ).length;
      notesAddedByDay.push({
        count: notesAddedThisDay,
        date: new Date(startOfDay),
      });
    }

    const notesAddedByWeek: NoteCount[][] = [];
    for (let i = 0; i < notesAddedByDay.length; i++) {
      if (i % 7 === 0) {
        notesAddedByWeek.push(notesAddedByDay.slice(i, i + 7));
      }
    }

    const maxNotesAddedDaily = Math.max(...notesAddedByDay.map((i) => i.count));

    return [notesAddedByWeek, maxNotesAddedDaily];
    // eslint-disable-next-line
  }, [noteCreationDates]);

  return (
    <Container style={{ maxWidth: "960px" }}>
      <ScrollContainer>
        {notesAddedByWeek.map((dailyCountsOfWeek, i) => {
          const monthIndex =
            i === 0
              ? null
              : dailyCountsOfWeek[0].date.getMonth() !== notesAddedByWeek[i - 1][0].date.getMonth()
                ? dailyCountsOfWeek[0].date.getMonth()
                : null;

          return (
            <div
              key={dailyCountsOfWeek[0].date.getTime()}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {monthIndex != null && (
                <div
                  style={{
                    position: "absolute",
                    top: -18,
                    left: 2,
                    fontSize: 12,
                  }}
                >
                  {MONTH_NAMES[monthIndex]}
                </div>
              )}
              {dailyCountsOfWeek.map((countOfDay) => (
                <DaySquareContainer
                  key={countOfDay.date.getTime()}
                  aria-label={`${countOfDay.date.toLocaleDateString()}\n${countOfDay.count || "No"} notes added`}
                  data-tooltip-direction={+countOfDay.date < middleDate ? "right" : "left"}
                  style={{
                    visibility: countOfDay.date.getTime() > now ? "hidden" : "visible",
                  }}
                >
                  <DaySquare
                    style={{
                      opacity: countOfDay.count === 0 ? 0.1 : (countOfDay.count / maxNotesAddedDaily) * 0.9 + 0.25,
                    }}
                  />
                </DaySquareContainer>
              ))}
            </div>
          );
        })}
      </ScrollContainer>
      <StreakChartCover />
    </Container>
  );
};
