import React, { FC, ReactElement, useCallback } from "react";
import { useRouter } from "next/router";
import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { auth0ClientId, auth0Domain, isAuthEnabled, redirectUri } from "../utils/environment";

const AuthWrapper: FC<{ children: ReactElement }> = ({ children }) => {
  const router = useRouter();
  const onRedirectCallback = useCallback((appState: AppState | undefined) => {
    router.replace(appState?.returnTo ?? "/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAuthEnabled) {
    return children;
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={redirectUri}
      useRefreshTokens
      scope="openid profile email offline_access"
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      audience="https://ideaflow.auth0.com/api/v2/"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthWrapper;
