import { ModalContainer } from "../../export/ModalContainer";
import { ModalEnum, closeModal } from "../../model/modals";

export type PublishDescendantProps = { numRefs?: number };

export default function PublishDescendantsModal({ numRefs }: PublishDescendantProps) {
  return (
    <ModalContainer
      type={ModalEnum.PUBLISH_DESCENDANTS}
      buttons={[
        {
          text: "Yes",
          onClick: () => closeModal(ModalEnum.PUBLISH_DESCENDANTS, true),
        },
        {
          text: "No",
          onClick: () => closeModal(ModalEnum.PUBLISH_DESCENDANTS, false),
        },
      ]}
    >
      <div>
        <h1>Publish all referenced notes?</h1>
        <p>This note has {numRefs} unpublished reference(s). Would you like to publish them too?</p>
      </div>
    </ModalContainer>
  );
}
