import { useState } from "react";
import styled from "@emotion/styled";
import { colors } from "../utils/style";
import { ModalEnum, closeModal, lockModal, unlockModal } from "../model/modals";
import { useApiClient } from "../api/client";
import { useAuth } from "../auth/useAuth";
import { Spinner } from "../utils/Spinner";
import { ModalContainer } from "./ModalContainer";

const Input = styled.input`
  display: flex;
  border-radius: 4px;
  border: 1px solid ${colors.border.secondary};
  background: ${colors.bg.tertiary};
  height: 32px;
  :focus-within {
    border: 1px solid ${colors.border.primary};
  }
`;

export const ResetPasswordModalContent = () => {
  const [stage, setStage] = useState<"confirm" | "resetting" | "failed" | "success">("confirm");
  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const apiClient = useApiClient();
  const { user } = useAuth();

  const handleSubmit = async () => {
    if (!newPassword || !confirmNewPassword) {
      setErrorMessage("Please fill in all fields");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New password and confirm new password must match");
      return;
    }
    setStage("resetting");
    lockModal(ModalEnum.RESET_PASSWORD);
    if (!user) return;
    try {
      const result = await apiClient.users.update({
        password: newPassword,
      });
      if (result.updated) {
        setStage("success");
        setErrorMessage("");
        closeModal(ModalEnum.RESET_PASSWORD);
      } else {
        throw new Error(result.error);
      }
    } catch (error) {
      const MAX_ERROR_MESSAGE_LENGTH = 50;
      console.log({ error });
      const errorMessage = error instanceof Error ? error.message.split(":")[1]?.trim() : "Unknown error";
      const truncatedErrorMessage =
        errorMessage.length > MAX_ERROR_MESSAGE_LENGTH
          ? errorMessage.substring(0, MAX_ERROR_MESSAGE_LENGTH) + "..."
          : errorMessage;
      setErrorMessage(truncatedErrorMessage);
      setStage("failed");
    } finally {
      unlockModal(ModalEnum.RESET_PASSWORD);
    }
  };

  return (
    <ModalContainer
      type={ModalEnum.RESET_PASSWORD}
      buttons={
        stage !== "success" && stage !== "resetting"
          ? [
              {
                text: "Reset",
                onClick: handleSubmit,
              },
              {
                text: "Cancel",
                onClick: () => closeModal(ModalEnum.RESET_PASSWORD),
              },
            ]
          : []
      }
    >
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="newPassword">New Password:</label>
          <Input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label htmlFor="confirmNewPassword">Confirm New Password:</label>
          <Input
            type="password"
            id="confirmNewPassword"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        {stage === "resetting" && (
          <div>
            <Spinner style={{ width: 30, height: 30 }} />
            <p>Resetting your password...</p>
          </div>
        )}
        {stage === "failed" && !errorMessage && (
          <>
            <p>Something went wrong and we couldn't reset your password</p>
          </>
        )}
        {stage == "success" && (
          <>
            <p style={{ color: "green" }}>Your password has been reset successfully</p>
          </>
        )}
      </form>
    </ModalContainer>
  );
};
