import { useEffect } from "react";
import posthog from "posthog-js";
import { useAuth } from "../auth/useAuth";
import { isAnalyticsEnabled } from "../utils/environment";
import { trackEvent } from "./analyticsHandlers";

export default function useAnalytics() {
  const { user } = useAuth();

  useEffect(() => {
    if (!user) return;
    if (user.email === null) return;
    if (isAnalyticsEnabled) posthog.identify(user.id, { email: user.email });
    trackEvent("session_started");
  }, [user]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    const trackFocus = () => trackEvent("focus_window");
    const trackBlur = () => trackEvent("blur_window");
    window.addEventListener("focus", trackFocus);
    window.addEventListener("blur", trackBlur);
    return () => {
      window.removeEventListener("focus", trackFocus);
      window.removeEventListener("blur", trackBlur);
    };
  }, []);
}
