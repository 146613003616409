import styled from "@emotion/styled";
import { useAtom, useAtomValue } from "jotai";
import Image from "next/image";
import { useLogoutAllTabs } from "../auth/Logout";
import { useAuth } from "../auth/useAuth";
import { StandardButton } from "../components/StandardButton";
import { addToast } from "../components/Toast";
import { Toggle } from "../components/Toggle";
import { redisplayVideoAtom } from "../components/UserOnboarding";
import { Content, Header, MobileFullScreenContainer } from "../modal/Modal";
import { userSettingsAtom } from "../model/atoms";
import { ModalEnum, closeModal, openModal } from "../model/modals";
import { upsertUserSettings } from "../model/userSettings";
import { Spinner } from "../utils/Spinner";
import { isProd, isRunningInIOSWebview, isSimonSearchEnabled, publicVersion } from "../utils/environment";
import { colors } from "../utils/style";
import { SettingsMenuAppInstallButton } from "../components/AppInstallButton";
import { ColorScheme, useColorScheme } from "./ColorScheme";
import { DefaultTranscriptionLanguage } from "./DefaultTranscriptionLanguage";
import { DeleteWarningThreshold } from "./DeleteWarningThreshold";
import { StatsSection } from "./StatsSection";
import { StreakChart } from "./StreakChart";
import { UserHandle } from "./UserHandle";
import { Logo } from "../components/Icons/Logo";

const StandardSettingsButton = styled(StandardButton)`
  height: 28px;
  min-width: 50px;
  margin-top: 10px;
  display: inline-block;
  margin-right: 12px;
`;

const Title = styled.h2`
  color: ${colors.text.primary};
  font-size: 16px;
  font-weight: 600;
`;

const InteractiveLink = styled.button`
  cursor: pointer;
  color: ${colors.text.accent};
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0px;
  background: transparent;
  border: 0;
  padding: 0;
  display: block;
  :hover {
    text-decoration: underline;
  }
`;

const DeleteButton = styled.button`
  cursor: pointer;
  color: ${colors.text.negative};
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0px;
  background: transparent;
  border: 0;
  padding: 0;
  display: block;
  :hover {
    text-decoration: underline;
  }
`;

const Line = styled.hr`
  border: 0;
  margin: 0;
  border-bottom: 1px solid ${colors.border.secondary};
  width: 100%;
`;

export function SettingsModalContent() {
  const { user } = useAuth();
  const { logoutAllTabs, isLoggingOut } = useLogoutAllTabs();
  const userSettings = useAtomValue(userSettingsAtom);
  const setRedisplayVideo = useAtom(redisplayVideoAtom)[1];
  const colorScheme = useColorScheme();

  return (
    <MobileFullScreenContainer>
      <Header closeModal={() => closeModal(ModalEnum.SETTINGS)} />
      <Content>
        <div>
          <div className="account" style={{ marginBottom: 10 }}>
            <Title>Account</Title>
            <p style={{ fontSize: 14, marginBottom: 0 }}>
              <span style={{ color: colors.text.secondary }}>Email:</span> {user?.email}
            </p>
            {!isProd && (
              <p style={{ fontSize: 14, marginBottom: 0 }}>
                <span style={{ color: colors.text.secondary }}>User ID:</span> {user?.id}
              </p>
            )}
            <p style={{ fontSize: 14, marginBottom: 0 }}>
              <UserHandle />
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InteractiveLink onClick={() => logoutAllTabs()} disabled={isLoggingOut} style={{ marginRight: "10px" }}>
                {isLoggingOut ? <Spinner style={{ width: 30, height: 30, margin: 5 }} /> : "Logout"}
              </InteractiveLink>
              {user?.sub.startsWith("auth0|") && (
                <InteractiveLink
                  onClick={() => {
                    openModal(ModalEnum.RESET_PASSWORD);
                  }}
                >
                  Reset Password
                </InteractiveLink>
              )}
            </div>
            <SettingsMenuAppInstallButton />
          </div>
          <Line />
          <div className="preferences" style={{ marginBottom: 10 }}>
            <Title>Preferences</Title>
            <ColorScheme />
            <div>
              <SettingToggle
                label="Link Unfurling"
                description="After pasting a link, automatically insert the title of the page."
                isChecked={userSettings.unfurlingEnabled}
                onChange={() => {
                  upsertUserSettings({
                    unfurlingEnabled: !userSettings.unfurlingEnabled,
                  });
                }}
              />
              <SettingToggle
                label="Convert @ into hashtag"
                description="After typing @, convert it into a hashtag (#@). Enabling this feature also adds a @ button to the mobile keyboard bar"
                isChecked={userSettings.atSignHashtagEnabled}
                onChange={() => {
                  upsertUserSettings({
                    atSignHashtagEnabled: !userSettings.atSignHashtagEnabled,
                  });
                }}
              />
              <SettingToggle
                label="Convert ~ into hashtag"
                description="After typing ~, convert it into a hashtag (#~). Enabling this feature also adds a ~ button to the mobile keyboard bar"
                isChecked={userSettings.tildeSignHashtagEnabled}
                onChange={() => {
                  upsertUserSettings({
                    tildeSignHashtagEnabled: !userSettings.tildeSignHashtagEnabled,
                  });
                }}
              />
              <SettingToggle
                label="Show To-do Strikethroughs"
                description={"Strikethrough will appear on to-do items when they are marked as completed."}
                isChecked={userSettings.showTodoStrikethrough}
                onChange={() => {
                  upsertUserSettings({
                    showTodoStrikethrough: !userSettings.showTodoStrikethrough,
                  });
                }}
              />
              <SettingToggle
                label="Extract text from images"
                description={"Text will be extracted from images and added below them in the note"}
                isChecked={userSettings.imageOcrEnabled}
                onChange={() => {
                  upsertUserSettings({
                    imageOcrEnabled: !userSettings.imageOcrEnabled,
                  });
                }}
              />
              <div>
                <p style={{ fontSize: 14, color: colors.text.primary }}>Experimental</p>
                <SettingToggle
                  label="Select smart autocomplete with comma"
                  description="Once enabled, you can select autocomplete suggestions using comma while creating a reference after a bullet-colon."
                  isChecked={userSettings.commaAutocompleteEnabled}
                  onChange={() => {
                    upsertUserSettings({
                      commaAutocompleteEnabled: !userSettings.commaAutocompleteEnabled,
                    });
                  }}
                />
                <SettingToggle
                  label="Smart autocomplete (experimental)"
                  description='An experimental feature which prompts you to create references between notes without explicitly initiating one. For example, if you type "#conversation with", it will prompt you with suggested references after it.'
                  isChecked={userSettings.smartAutocompleteEnabled}
                  onChange={() => {
                    upsertUserSettings({
                      smartAutocompleteEnabled: !userSettings.smartAutocompleteEnabled,
                    });
                  }}
                />
                <SettingToggle
                  label="Append extracted attributes to canonical notes (experimental)"
                  description={
                    'An experimental feature which automatically appends attributes extraced with "Smart Entity Extract" to the identified canonical note.'
                  }
                  isChecked={userSettings.appendExtractsToCanonicalNotes}
                  onChange={() => {
                    upsertUserSettings({
                      appendExtractsToCanonicalNotes: !userSettings.appendExtractsToCanonicalNotes,
                    });
                  }}
                />
                <SettingToggle
                  label="Ignore autocorrect on hashtags"
                  description={
                    "Ignore autocorrect on hashtags. Right now, it only detects when autocorrect inserts a space while swipe typing and removes it. In the future it will detect and ignore more cases."
                  }
                  isChecked={userSettings.ignoreHashtagAutocorrect}
                  onChange={() => {
                    upsertUserSettings({
                      ignoreHashtagAutocorrect: !userSettings.ignoreHashtagAutocorrect,
                    });
                  }}
                />
                {isSimonSearchEnabled && (
                  <SettingToggle
                    label="Semantic search"
                    description={
                      "Enable semantic search that adds extra search results based on the context of the search query."
                    }
                    isChecked={userSettings.simonSearchEnabled}
                    onChange={() => {
                      upsertUserSettings({
                        simonSearchEnabled: !userSettings.simonSearchEnabled,
                      });
                    }}
                  />
                )}
              </div>
            </div>

            <DefaultTranscriptionLanguage />
            <DeleteWarningThreshold />

            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: colors.text.primary,
                  marginTop: 10,
                }}
              >
                Feature hints
              </div>
              <div
                style={{
                  color: colors.text.secondary,
                  marginTop: 5,
                }}
              >
                Ideaflow occasionally displays hints about how to use a feature after using it for the first time. Reset
                to see them again.
              </div>
              <StandardSettingsButton
                onClick={() => {
                  upsertUserSettings({
                    seenToDoToast: false,
                    seenAtSignToast: false,
                    seenOpenAsPageHint: false,
                    seenUnfurl: false,
                    seenUnfurlUndo: false,
                    // seenExportNotesOnSaveToast: false,
                  });
                  addToast("Feature hints reset");
                }}
              >
                Reset feature hints
              </StandardSettingsButton>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: colors.text.primary,
                  marginTop: 10,
                }}
              >
                Custom shortcuts
              </div>
              <div
                style={{
                  color: colors.text.secondary,
                  marginTop: 5,
                }}
              >
                Click to set your own shortcuts for frequent actions.
              </div>
              <div>
                <StandardSettingsButton
                  onClick={() => {
                    openModal(ModalEnum.HELP, { customProps: { scrollTo: "keyboard-shortcuts" } });
                  }}
                >
                  Set shortcuts
                </StandardSettingsButton>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  color: colors.text.primary,
                  marginTop: 10,
                }}
              >
                Onboarding
              </div>
              <div
                style={{
                  color: colors.text.secondary,
                  marginTop: 5,
                }}
              >
                Click to restart the onboarding tutorial.
              </div>
              <div>
                <StandardSettingsButton
                  onClick={() => {
                    closeModal(ModalEnum.SETTINGS);
                    setRedisplayVideo(false);
                    upsertUserSettings({
                      seenOnboarding: false,
                    });
                  }}
                >
                  Replay Walkthrough
                </StandardSettingsButton>
                <StandardSettingsButton
                  onClick={() => {
                    closeModal(ModalEnum.SETTINGS);
                    setRedisplayVideo(true);
                  }}
                >
                  Video
                </StandardSettingsButton>
              </div>
            </div>
          </div>

          <Line />
          <Title>Your Ideaflow</Title>
          <StreakChart />
          <StatsSection />
          <InteractiveLink onClick={() => openModal(ModalEnum.IMPORT)}>Import Notes</InteractiveLink>
          <InteractiveLink onClick={() => openModal(ModalEnum.EXPORT)}>Export Notes</InteractiveLink>
          <Line />
          <Title>Delete account</Title>
          <p>
            If you would like to delete your account, including all of your notes, please click the button below. This
            action is permanent and cannot be undone.
          </p>
          <DeleteButton onClick={() => openModal(ModalEnum.DELETE_ACCOUNT)}>Delete this account</DeleteButton>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
            }}
          >
            <i>version {publicVersion}</i>
            <a href="https://ideaflow.io" target="_blank" rel="noopener noreferrer" style={{ marginTop: "10px" }}>
              {isRunningInIOSWebview ? (
                <Logo fill={colorScheme !== "dark" ? "black" : "white"} height="20" />
              ) : (
                <Image
                  alt="logo"
                  src={colorScheme !== "dark" ? "/logo-light.png" : "/logo-dark.png"}
                  width="120px"
                  height="24px"
                ></Image>
              )}
            </a>
          </div>
        </div>
      </Content>
    </MobileFullScreenContainer>
  );
}

function SettingToggle({
  label,
  description,
  isChecked,
  onChange,
}: {
  label: string;
  description?: string;
  isChecked: boolean;
  onChange: () => void;
}) {
  return (
    <div style={{ margin: "10px 0" }}>
      <div style={{ display: "flex" }}>
        <Toggle isChecked={isChecked} onChange={onChange} />
        <span onClick={onChange} style={{ cursor: "pointer" }}>
          {label}
        </span>
      </div>
      {description && <div style={{ color: colors.text.secondary }}>{description}</div>}
    </div>
  );
}

export const useTodoStrikethroughWatcher = () => {
  const userSettings = useAtomValue(userSettingsAtom);

  if (userSettings.showTodoStrikethrough) {
    document.body.style.setProperty("--todo-strikethrough", "line-through");
  } else {
    document.body.style.setProperty("--todo-strikethrough", "none");
  }
};
