import styled from "@emotion/styled";
import { ModalEnum, closeModal } from "../model/modals";
import { Secondary } from "../share/Secondary";
import { colors, mediaQueries } from "../utils/style";

/**
 * Fits the component to the generic modal container.
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 200px;
  max-width: 500px;
  ${mediaQueries.mobile} {
    min-width: unset;
    min-height: unset;
    width: 100%;
    height: 100%;
  }
`;

/**
 * Scrollable content container. On mobile, it has a large padding
 * at the bottom to make room for the create new note button.
 */
const Content = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 20px 20px 20px;
  ${mediaQueries.mobile} {
    padding: 0 20px 120px 20px;
  }
`;

function Header({ closeModal }: { closeModal: () => void }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          right: 10,
          top: 0,
          cursor: "pointer",
          fontSize: 30,
          color: colors.text.secondary,
        }}
        onClick={closeModal}
      >
        ×
      </div>
    </div>
  );
}

export function Footer({ children }: { children: React.ReactNode }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: "12px",
      }}
    >
      {children}
    </div>
  );
}

export function ModalContainer({
  children,
  type,
  buttons = [],
}: {
  children: React.ReactNode;
  type: ModalEnum;
  buttons?: { text: string; onClick: () => void; disabled?: boolean }[];
}) {
  return (
    <Container>
      <Header closeModal={() => closeModal(type)} />
      <Content>
        {children}
        <Footer>
          {buttons.map(({ text, onClick, disabled }) => (
            <Secondary key={text} onClick={onClick} disabled={disabled ?? false}>
              {text}
            </Secondary>
          ))}
        </Footer>
      </Content>
    </Container>
  );
}
