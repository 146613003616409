import { useCallback, useState } from "react";
import { useAuth } from "../auth/useAuth";
import { trackEvent } from "../analytics/analyticsHandlers";
import { assert } from "../utils/assert";
import { appNoteStore } from "../model/services";
import { Note } from "../../shared/types";
import { modelVersion } from "../utils/environment";
import { ModalEnum, closeModal, lockModal, unlockModal } from "../model/modals";
import logger from "../utils/logger";
import { useUpdateEditor } from "../editorPage/atomHelpers/editorUpdate";
import { useNotifySidebarUpdate } from "../sidebar/atoms/sidebarUpdate";
import { ModalContainer } from "./ModalContainer";
import {
  copyNotesFromDifferentUser,
  handleConflict,
  notesFromAppleNotes,
  notesFromExport,
  notesFromMarkdown,
  notesFromPlainText,
} from "./procedures/importers";

enum ImportStatus {
  NotStarted,
  Importing,
  NoNewNote,
  Imported,
  Error,
}

enum ImportFormat {
  IdeaflowFile = "JSON",
  PlainTextFile = "PLAIN_TEXT",
  Paste = "CLIPBOARD",
  AppleNotes = "HTML",
  MarkdownFile = "MARKDOWN_FILE",
}

export enum ConflictStrategy {
  Replace = "replace",
  Merge = "merge",
  Ignore = "skip",
  Duplicate = "duplicate",
}

function createBatchName(filename: string) {
  return `${filename} import on ${new Date().toLocaleString()}`;
}

export function ImportModalContent() {
  const { user } = useAuth();
  const [importStatus, setImportStatus] = useState(ImportStatus.NotStarted);
  const [selectedFormat, setSelectedFormat] = useState(ImportFormat.Paste);
  const [conflictStrategy, setConflictStrategy] = useState(ConflictStrategy.Ignore);
  const [files, setFiles] = useState<{ text: string; filename: string }[] | null>(null);
  const [message, setMessage] = useState("");
  const [plainTextImportData, setPlainTextImportData] = useState("");
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const updateEditor = useUpdateEditor();
  const updateSidebar = useNotifySidebarUpdate();

  const isFileImport = selectedFormat !== ImportFormat.Paste;

  const doImport = useCallback(() => {
    setImportStatus(ImportStatus.Importing);
    lockModal(ModalEnum.IMPORT);
    setMessage("Setting up import...");
    // Do import
    try {
      assert(!!user, "Missing user");

      // Create notes from import source
      let notes: Note[] = [];
      const errors: { error: Error; note: Note }[] = [];
      if (selectedFormat === ImportFormat.IdeaflowFile) {
        assert(!!files?.length, "Missing file");
        for (const file of files) {
          const data = JSON.parse(file.text);
          const res = notesFromExport(data, modelVersion);
          if (res.userId !== user.id) {
            // If the export is from a different user, we need to assign new
            // note ids. If we don't do this, the client will try to push the
            // note to the server, which will fail because a note with that id
            // belongs to another user. (ENT-2354)
            res.notes = copyNotesFromDifferentUser(res?.notes, (n) => appNoteStore.notePositions.generateFirst(n));
          }
          notes.push(
            ...res.notes.map((n, i) => ({
              ...n,
              importSource: file.filename,
              importBatch: createBatchName(file.filename),
            })),
          );
          errors.push(...res.errors);
        }
        if (errors.length > 0) {
          logger.error("Errors during import:", { context: { errors } });
        }
        setErrors(errors.map((e) => e.error.message));
        trackEvent("import_from_json");
      } else if (selectedFormat === ImportFormat.MarkdownFile) {
        assert(!!files?.length, "Missing file");
        let importError: { id: string; note: any; error: Error } | null = null;
        const res = notesFromMarkdown(files, (n) => appNoteStore.notePositions.generateFirst(n));
        notes.push(
          ...res.notes.map((n, i) => ({
            ...n,
            importSource: files[i].filename,
            importBatch: createBatchName(files[i].filename),
          })),
        );
        importError = res.error;

        if (importError) {
          logger.error("Errors during import:", { context: { error: importError } });
          setErrors([importError.error.message]);
        }
        trackEvent("import_from_markdown");
      } else if (selectedFormat === ImportFormat.PlainTextFile) {
        assert(!!files?.length, "Missing file");
        for (const file of files) {
          const res = notesFromPlainText(file.text, (n) => appNoteStore.notePositions.generateFirst(n));
          notes.push(
            ...res.map((n) => ({
              ...n,
              importSource: file.filename,
              importBatch: createBatchName(file.filename),
            })),
          );
        }
        trackEvent("import_from_plaintext");
      } else if (selectedFormat === ImportFormat.Paste) {
        notes = notesFromPlainText(plainTextImportData, (n) => appNoteStore.notePositions.generateFirst(n)).map(
          (n) => ({
            ...n,
            importSource: "clipboard",
            importBatch: createBatchName("clipboard"),
          }),
        );
        trackEvent("import_from_clipboard");
      } else if (selectedFormat === ImportFormat.AppleNotes) {
        assert(!!files?.length, "Missing file");
        notes = notesFromAppleNotes(files, (n) => appNoteStore.notePositions.generateFirst(n)).map((n) => ({
          ...n,
          importSource: "apple-notes",
          importBatch: createBatchName("apple-notes"),
        }));
        trackEvent("import_from_apple_notes");
      }

      // Import notes
      if (conflictStrategy === ConflictStrategy.Ignore) {
        let numIgnored = 0;
        const notesToImport = notes.filter((note) => {
          if (!appNoteStore.has(note.id)) return true;
          else {
            numIgnored++;
            return false;
          }
        });
        appNoteStore.upsert(notesToImport, true);
        let msg = `Imported ${notesToImport.length} notes`;
        if (numIgnored > 0) {
          msg += `, ignoring ${numIgnored} conflicting notes`;
        }
        setMessage(msg);
      } else if (conflictStrategy === ConflictStrategy.Replace) {
        let numReplaced = 0;
        const notesToImport: Note[] = [];
        for (const importingNote of notes) {
          const existingNote = appNoteStore.get(importingNote.id);
          if (!existingNote) {
            notesToImport.push(importingNote);
          } else {
            notesToImport.push(handleConflict(importingNote, existingNote, conflictStrategy));
            numReplaced++;
          }
        }
        appNoteStore.upsert(notesToImport, true);
        let msg = `Imported ${notesToImport.length} notes`;
        if (numReplaced > 0) {
          msg += `, replacing ${numReplaced} existing notes`;
        }
        setMessage(msg);
      } else if (conflictStrategy === ConflictStrategy.Merge) {
        let numMerged = 0;
        const notesToImport: Note[] = [];
        for (const importingNote of notes) {
          const existingNote = appNoteStore.get(importingNote.id);
          if (!existingNote) {
            notesToImport.push(importingNote);
          } else {
            notesToImport.push(handleConflict(importingNote, existingNote, conflictStrategy));
            numMerged++;
          }
        }
        appNoteStore.upsert(notesToImport, true);
        let msg = `Imported ${notesToImport.length} notes`;
        if (numMerged > 0) {
          msg += `, merging ${numMerged} existing notes`;
        }
        setMessage(msg);
      } else if (conflictStrategy === ConflictStrategy.Duplicate) {
        let numDuplicated = 0;
        const notesToImport: Note[] = [];
        for (const importingNote of notes) {
          const existingNote = appNoteStore.get(importingNote.id);
          if (!existingNote) {
            notesToImport.push(importingNote);
          } else {
            notesToImport.push(handleConflict(importingNote, existingNote, conflictStrategy));
            numDuplicated++;
          }
        }
        appNoteStore.upsert(notesToImport, true);
        let msg = `Imported ${notesToImport.length} notes`;
        if (numDuplicated > 0) {
          msg += `, duplicating ${numDuplicated} existing notes`;
        }
        setMessage(msg);
      }
      updateEditor();
      updateSidebar();
      setImportStatus(ImportStatus.Imported);
      unlockModal(ModalEnum.IMPORT);
    } catch (e) {
      logger.error("Import failed", { error: e });
      setImportStatus(ImportStatus.Error);
      unlockModal(ModalEnum.IMPORT);
      setMessage(e instanceof Error ? e.message : String(e));
    }
  }, [
    files,
    user,
    setMessage,
    setImportStatus,
    selectedFormat,
    plainTextImportData,
    conflictStrategy,
    updateEditor,
    updateSidebar,
  ]);

  if (importStatus === ImportStatus.Importing) {
    return (
      <ModalContainer type={ModalEnum.IMPORT}>
        <div>
          <h1>Importing...</h1>
          <span>
            We are now importing your notes. This action cannot be cancelled. Notes already present are not imported.
            <br />
            <br />
            <i>{message}</i>
          </span>
        </div>
      </ModalContainer>
    );
  } else if (importStatus === ImportStatus.Error) {
    return (
      <ModalContainer
        type={ModalEnum.IMPORT}
        buttons={[
          {
            text: "Close",
            onClick: () => closeModal(ModalEnum.IMPORT),
          },
        ]}
      >
        <h1>Error</h1>
        <div style={{ margin: "24px 0px" }}>
          <p>
            <i>{message}</i>
          </p>
          <p>See console for more details.</p>
        </div>
      </ModalContainer>
    );
  } else if (importStatus === ImportStatus.Imported) {
    return (
      <ModalContainer
        type={ModalEnum.IMPORT}
        buttons={[
          {
            text: "Close",
            onClick: () => closeModal(ModalEnum.IMPORT),
          },
        ]}
      >
        <h1>Imported</h1>
        <div style={{ margin: "24px 0px" }}>
          <p>All done!</p>
          <p>{message}</p>
          {errors.length > 0 && (
            <div>
              <h2>Errors</h2>
              <p>Failed to import {errors.length} notes, with the following errors:</p>
              <ul>
                {Array.from(new Set(errors))
                  .sort()
                  .map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </ModalContainer>
    );
  } else {
    return (
      <ModalContainer
        type={ModalEnum.IMPORT}
        buttons={[
          {
            text: "Close",
            onClick: () => closeModal(ModalEnum.IMPORT),
          },
          {
            text: "Import",
            onClick: doImport,
            disabled:
              (isFileImport && !files) || (selectedFormat === ImportFormat.Paste && plainTextImportData.length === 0),
          },
        ]}
      >
        <div>
          <h1>Import Notes</h1>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <div id="select-type">
              <span style={{ margin: "12px 0px" }}>Where are the notes coming from? </span>
              <div>
                <div>
                  <input
                    style={{ marginRight: 8 }}
                    type="radio"
                    id="paste"
                    onChange={() => setSelectedFormat(ImportFormat.Paste)}
                    checked={selectedFormat === ImportFormat.Paste}
                  />
                  <label htmlFor="paste">From paste</label>
                </div>
                <input
                  style={{ marginRight: 8 }}
                  type="radio"
                  id="plaintext"
                  onChange={() => setSelectedFormat(ImportFormat.PlainTextFile)}
                  checked={selectedFormat === ImportFormat.PlainTextFile}
                />
                <label htmlFor="plaintext">From a plain text file</label>
              </div>
              <div>
                <input
                  style={{ marginRight: 8 }}
                  type="radio"
                  id="markdown-file"
                  onChange={() => setSelectedFormat(ImportFormat.MarkdownFile)}
                  checked={selectedFormat === ImportFormat.MarkdownFile}
                />
                <label htmlFor="markdown-file">From Markdown file</label>
              </div>
              <div>
                <input
                  style={{ marginRight: 8 }}
                  type="radio"
                  id="ideaflow"
                  onChange={() => setSelectedFormat(ImportFormat.IdeaflowFile)}
                  checked={selectedFormat === ImportFormat.IdeaflowFile}
                />
                <label htmlFor="ideaflow">From an Ideaflow export (.if.json file)</label>
              </div>
              <div>
                <input
                  style={{ marginRight: 8 }}
                  type="radio"
                  id="apple-notes"
                  onChange={() => setSelectedFormat(ImportFormat.AppleNotes)}
                  checked={selectedFormat === ImportFormat.AppleNotes}
                />
                <label htmlFor="apple-notes">From Apple Notes</label>
              </div>
            </div>
            <div id="select-source">
              {selectedFormat === ImportFormat.Paste && (
                <>
                  <br />
                  <textarea
                    style={{ width: "100%" }}
                    placeholder={"Note 1\n--\nNote 2"}
                    rows={8}
                    onChange={(e) => setPlainTextImportData(e.target.value)}
                  />
                </>
              )}
              {[
                ImportFormat.IdeaflowFile,
                ImportFormat.PlainTextFile,
                ImportFormat.AppleNotes,
                ImportFormat.MarkdownFile,
              ].includes(selectedFormat) && (
                <div>
                  <input
                    id="file"
                    type="file"
                    accept={
                      selectedFormat === ImportFormat.IdeaflowFile
                        ? ".json"
                        : selectedFormat === ImportFormat.MarkdownFile
                          ? ".md"
                          : ""
                    }
                    multiple
                    onChange={(event) => {
                      const input = event.target;
                      if (!input.files) return;
                      const decoder = new TextDecoder(
                        selectedFormat === ImportFormat.AppleNotes ? "utf-16be" : "utf-8",
                      );
                      const files: { text: string; filename: string }[] = [];
                      for (const file of input.files) {
                        const reader = new FileReader();
                        reader.onload = () => {
                          let text: string;
                          if (reader.result instanceof ArrayBuffer) {
                            text = decoder.decode(reader.result);
                          } else if (typeof reader.result === "string") {
                            text = reader.result;
                          } else {
                            logger.error("file cannot be read or is empty:" + file.name);
                            return;
                          }
                          files.push({ text, filename: file.name });
                        };
                        reader.readAsArrayBuffer(file);
                      }
                      setFiles(files);
                    }}
                  />
                  <div>
                    {ImportFormat.PlainTextFile === selectedFormat && (
                      <span>(note separator is newline, double dash, newline: "\\n--\\n")</span>
                    )}
                    {ImportFormat.AppleNotes === selectedFormat && (
                      <span>
                        (Files should be exported using method described{" "}
                        <a
                          href="https://bear.app/faq/migrate-from-apple-notes/"
                          target="_blank"
                          rel="noreferrer"
                          className="link"
                        >
                          here
                        </a>
                        . This method may not export hashtags from Apple Notes.
                      </span>
                    )}
                  </div>
                </div>
              )}
              {selectedFormat === ImportFormat.IdeaflowFile && (
                <>
                  <div style={{ margin: "12px auto" }}>
                    <span style={{ marginRight: 8 }}>
                      If the export contains a note that's already in your account, what should we do with it?
                    </span>
                    <select
                      value={conflictStrategy}
                      onChange={(e) => {
                        setConflictStrategy(e.target.value as any);
                      }}
                    >
                      <option value={ConflictStrategy.Ignore}>Keep original</option>
                      <option value={ConflictStrategy.Replace}>Replace</option>
                      <option value={ConflictStrategy.Duplicate}>Duplicate</option>
                      <option value={ConflictStrategy.Merge}>Merge</option>
                    </select>
                  </div>
                  <div>
                    <span style={{ marginRight: 8 }}>Include deleted notes?</span>
                    <input
                      type="checkbox"
                      checked={includeDeleted}
                      onChange={(e) => setIncludeDeleted(e.target.checked)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <p>
          To ask for more formats or assistance, please{" "}
          <a href="mailto:support@ideaflow.io" className="link" style={{ wordBreak: "unset" }}>
            contact us
          </a>
        </p>
      </ModalContainer>
    );
  }
}
