import styled from "@emotion/styled";
import { X } from "react-feather";
import { useState } from "react";
import { NoteId } from "../../shared/types";
import { ModalEnum, closeModal } from "../model/modals";
import { Card } from "../help/HelpModalContent";
import { updateSearchQuery } from "../search/useSearchQuery";
import { appNoteStore } from "../model/services";
import { useSimonSearch } from "./useSimonSearch";
import { colors } from "./style";
import logger from "./logger";

type Ref = {
  noteId: NoteId;
  index: number;
};

type Message = {
  sender: string;
  text: string;
  refIds?: NoteId[];
};

const deriveRefs = (ids: string[]): Ref[] => {
  const noteIdToRef = new Map<string, Ref>();
  let index = 1;
  return ids.map((noteId) => {
    if (!noteIdToRef.has(noteId)) {
      noteIdToRef.set(noteId, { noteId, index });
      index++;
    }
    return noteIdToRef.get(noteId)!;
  });
};

export function SimonQueryModalContent() {
  const [chat, setChat] = useState<Message[]>([{ sender: "Simon", text: "Hi! How can I help you today?" }]);
  const [inputValue, setInputValue] = useState("");
  const [isSending, setIsSending] = useState(false);
  const { simonQuery } = useSimonSearch();

  const sendQuery = (text: string) => {
    setChat((prev) => [...prev, { sender: "You", text }]);
    setIsSending(true);
    simonQuery(text)
      .then(({ answer, ids }) => {
        setChat((prev) => [...prev, { sender: "Simon", text: answer, refIds: ids }]);
      })
      .catch((e) => {
        logger.error("Failed to send Simon query", { context: { text, e } });
        setChat((prev) => [
          ...prev,
          { sender: "Simon", text: "I'm sorry, an error occurred. Please, try again a bit later." },
        ]);
      })
      .finally(() => setIsSending(false));
  };

  const handleSend = () => {
    if (inputValue.trim() === "") return; // Prevent sending if input is empty
    sendQuery(inputValue);
    setInputValue("");
  };

  const createClickableReferences = (text: string, refIds: NoteId[]) => {
    const parts = text.split(/(\[\d+\])/g);
    const refs = deriveRefs(refIds);

    let mostRecentlyReferredNoteId: string | null = null; // Prevents showing the same reference twice in a row (e.g. [1][1])
    let matchIndex = 0;

    return parts.map((part, i) => {
      if (part === "") return "";

      const match = part.match(/\[(\d+)\]/);
      if (match) {
        try {
          const { noteId, index } = refs[matchIndex++];

          // Prevent showing the same reference twice in a row
          if (mostRecentlyReferredNoteId === noteId) return "";
          mostRecentlyReferredNoteId = noteId;

          if (!appNoteStore.get(noteId)) {
            throw new Error(`Note with ID ${noteId} not found`);
          }

          return (
            <span
              key={i}
              style={{ cursor: "pointer", textDecoration: "underline", color: colors.text.accent }}
              onClick={() => updateSearchQuery({ jumpTo: noteId })}
            >
              [{index}]
            </span>
          );
        } catch (e) {
          logger.warn("Failed to parse reference in Simon Query", { context: { text, refIds } });
          mostRecentlyReferredNoteId = null;
          return "[...]";
        }
      } else {
        mostRecentlyReferredNoteId = null;
        return part;
      }
    });
  };

  return (
    <SimonQueryContainer onClick={(e) => e.stopPropagation()}>
      <Card>
        <header>
          <button onClick={() => closeModal(ModalEnum.SIMON_QUERY)}>
            <X size={20} />
          </button>
        </header>
        <ChatZone>
          <Chat style={{ flex: 1 }}>
            {chat.map((message, i) => (
              <div key={i}>
                <strong>{message.sender}: </strong>
                <span>{message.refIds ? createClickableReferences(message.text, message.refIds) : message.text}</span>
              </div>
            ))}
            {isSending && <div style={{ fontStyle: "italic", color: colors.text.accent }}>Simon is typing...</div>}
          </Chat>
          <div style={{ display: "flex", alignItems: "center", width: "100%", margin: "auto" }}>
            <Input
              id="simonQueryInput"
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              onKeyPress={(e) => e.key === "Enter" && handleSend()}
            />
            <Button onClick={handleSend} disabled={inputValue.trim() === ""}>
              Send
            </Button>
          </div>
        </ChatZone>
      </Card>
    </SimonQueryContainer>
  );
}

const SimonQueryContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: calc(var(--header-height) + 100px);
  right: 20px;
  bottom: 30px;
  display: flex;
  justify-content: center;
  pointer-events: none;
  * {
    pointer-events: auto;
  }
`;

const ChatZone = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const Chat = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 10px;
`;

const Input = styled.input`
  flex-grow: 1;
  padding: 10px 16px;
  color: var(--color-text-primary);
  border-radius: 4px 0 0 4px;
  border: none;
  outline: none;
  background-color: var(--color-bg-tertiary);
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: var(--color-button-bg);
  color: var(--color-bg-primary);
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:
    background-color 0.1s,
    color 0.1s;

  &:hover {
    background-color: var(--color-text-accent);
  }

  &:disabled {
    cursor: default;
    background-color: var(--color-bg-tertiary);
    color: var(--color-text-secondary);
  }
`;
