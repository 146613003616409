import React, { useEffect, useState } from "react";
import { appNoteStore } from "../model/services";
import { mapBlockTokens } from "../../shared/tokenIterators/mapBlockTokens";

export const StatsSection = () => {
  const [stats, setStats] = useState({
    noteCount: -1,
    wordCount: -1,
    totalHashtagCount: -1,
    uniqueHashtagCount: -1,
    linkCount: -1,
    imageCount: -1,
    totalCheckboxCount: -1,
    completedCheckboxCount: -1,
    referenceCount: -1,
  });

  useEffect(() => {
    let wordCount = 0;
    let totalHashtagCount = 0;
    let linkCount = 0;
    let imageCount = 0;
    let totalCheckboxCount = 0;
    let completedCheckboxCount = 0;
    let referenceCount = 0;
    const nodeIterator = mapBlockTokens((blockToken) => {
      if (blockToken.type === "paragraph") {
        blockToken.content.forEach((inToken) => {
          if (inToken.type === "text") {
            wordCount += inToken.content.split(" ").length;
          } else if (inToken.type !== "checkbox" && inToken.type !== "image") {
            wordCount += 1;
          }
          // do not make this an `else if` since we want too count it in the
          // word count above
          if (inToken.type === "hashtag") {
            totalHashtagCount += 1;
          } else if (inToken.type === "link") {
            linkCount += 1;
          } else if (inToken.type === "image") {
            imageCount += 1;
          } else if (inToken.type === "checkbox") {
            totalCheckboxCount += 1;
            if (inToken.isChecked) completedCheckboxCount += 1;
          } else if (inToken.type === "spaceship") {
            referenceCount += 1;
          }
        });
      }
      if (blockToken.type === "codeblock") {
        blockToken.content.forEach((inToken) => {
          wordCount += inToken.content.split(" ").length;
        });
      }
    });

    const notes = appNoteStore.getAll();
    const uniqueHashtagCount = appNoteStore.hashtags.getAllItems().length;

    notes.forEach((note) => {
      note.tokens.forEach(nodeIterator);
    });
    setStats({
      wordCount,
      noteCount: notes.length,
      totalHashtagCount,
      uniqueHashtagCount,
      linkCount,
      imageCount,
      totalCheckboxCount,
      completedCheckboxCount,
      referenceCount,
    });
  }, []);

  const wordsPerNote =
    stats.noteCount === 0
      ? 0
      : (stats.wordCount / stats.noteCount).toLocaleString(undefined, {
          maximumFractionDigits: 1,
        });

  return (
    <>
      <p>
        {stats.noteCount.toLocaleString()}
        {" notes with "}
        {stats.wordCount.toLocaleString()}
        {" words total "}
        {`(${wordsPerNote} words/note)`}
      </p>
      <p>
        {stats.uniqueHashtagCount.toLocaleString()}
        {" hashtags used "}
        {stats.totalHashtagCount.toLocaleString()}
        {" times total"}
      </p>
      {stats.referenceCount ? (
        <p>
          {stats.referenceCount.toLocaleString()}
          {" references between notes"}
        </p>
      ) : null}
      {stats.totalCheckboxCount ? (
        <p>
          {stats.completedCheckboxCount.toLocaleString()}
          {" / "}
          {stats.totalCheckboxCount.toLocaleString()} todo items completed
        </p>
      ) : null}
      {stats.imageCount ? (
        <p>
          {stats.imageCount.toLocaleString()}
          {" images"}
        </p>
      ) : null}
      {stats.linkCount ? (
        <p>
          {stats.linkCount.toLocaleString()}
          {" links"}
        </p>
      ) : null}
    </>
  );
};
