import { useAtomValue } from "jotai";
import { RadioButton } from "../components/RadioButton";
import { colors } from "../utils/style";
import { upsertUserSettings } from "../model/userSettings";
import { userSettingsAtom } from "../model/atoms";

export const DeleteWarningThreshold = () => {
  const deleteWarning = useAtomValue(userSettingsAtom).deleteWarningThreshold;

  return (
    <label style={{ display: "block", marginBottom: 12 }}>
      <div>Delete Warning Threshold</div>
      <p style={{ fontSize: 14, color: colors.text.secondary }}>
        Ideaflow warns you when you are deleting a large number of notes. You can set the warning threshold using the
        buttons below.
      </p>
      <RadioButton
        key="5"
        selected={deleteWarning === 5}
        onClick={() => {
          upsertUserSettings({ deleteWarningThreshold: 5 });
        }}
      >
        5
      </RadioButton>
      <RadioButton
        key="20"
        selected={deleteWarning === 20}
        onClick={() => {
          upsertUserSettings({ deleteWarningThreshold: 20 });
        }}
      >
        20
      </RadioButton>
      <RadioButton
        key="100"
        selected={deleteWarning === 100}
        onClick={() => {
          upsertUserSettings({ deleteWarningThreshold: 100 });
        }}
      >
        100
      </RadioButton>
      <RadioButton
        key="never"
        selected={deleteWarning === -1}
        onClick={() => {
          upsertUserSettings({ deleteWarningThreshold: -1 });
        }}
      >
        Never
      </RadioButton>
    </label>
  );
};
