import React from "react";
import { useAtomValue } from "jotai";
import { modalStackAtom } from "../model/modals";
import { zIndexes } from "../utils/style";
import { Modal } from "./Modal";

export function ModalStack() {
  const modalStack = useAtomValue(modalStackAtom);

  return (
    <div id="modal" style={{ zIndex: zIndexes.modalStack }}>
      {modalStack.map(({ name, options }) => (
        <Modal key={name} modalName={name} options={options} />
      ))}
    </div>
  );
}
