import { useSetAtom, useAtomValue } from "jotai";
import { useAuth } from "../auth/useAuth";
import { isPersistenceEnabled } from "../utils/environment";
import { useApiClient } from "../api/client";
import { accessTokenAtom, userInfoAtom } from "./atoms";

export const useUserInfo = () => {
  const { user } = useAuth();
  const setUserInfo = useSetAtom(userInfoAtom);
  const apiClient = useApiClient();
  const accessToken = useAtomValue(accessTokenAtom);

  const getUserInfo = async () => {
    if (!isPersistenceEnabled || !user || !accessToken) return;
    try {
      const userResponse = await apiClient.users.get(user.id);
      setUserInfo({ handle: userResponse.handle });
    } catch (e) {
      throw new Error("Could not retrieve server data");
    }
  };

  const changeUserInfo = async (newHandle: string): Promise<void> => {
    if (!isPersistenceEnabled) throw new Error("Can't change handle while persistence is disabled");
    const result = await apiClient.users.updateHandle(newHandle);
    if (!result.status) {
      throw new Error("Could not update handle");
    }
    setUserInfo({ handle: newHandle });
  };

  return { getUserInfo, changeUserInfo };
};
