import styled from "@emotion/styled";
import { X } from "react-feather";
import { HelpContent, HelpContentProps } from "../help/Help";
import { ModalEnum, closeModal } from "../model/modals";
import { Content, Header, MobileFullScreenContainer, ModalContainer } from "../modal/Modal";
import { colors } from "../utils/style";
import { isGreaterThanMobileWidth } from "../utils/environment";

export function HelpModalContent({ scrollTo }: HelpContentProps) {
  if (isGreaterThanMobileWidth) {
    return (
      <DesktopHelpContainer onClick={(e) => e.stopPropagation()}>
        <Card>
          <header>
            <button onClick={() => closeModal(ModalEnum.HELP)}>
              <X size={20} />
            </button>
          </header>
          <main>
            <HelpContent scrollTo={scrollTo} />
          </main>
        </Card>
      </DesktopHelpContainer>
    );
  } else {
    return (
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <MobileFullScreenContainer>
          <Header closeModal={() => closeModal(ModalEnum.HELP)} />
          <Content>
            <HelpContent scrollTo={scrollTo} />
          </Content>
        </MobileFullScreenContainer>
      </ModalContainer>
    );
  }
}

const DesktopHelpContainer = styled.div`
  position: absolute;
  z-index: 11;
  top: var(--header-height);
  right: 0;
  bottom: 200px;
  display: flex;
  justify-content: center;
  height: 100%;
  pointer-events: none;
  * {
    pointer-events: auto;
  }
`;

export const Card = styled.div`
  background: ${colors.bg.primary};
  border: 1px solid ${colors.border.secondary};
  padding: 0px 12px 12px 12px;
  margin: 12px 12px 80px 12px;
  box-shadow: 0px 1px 5px -1px #00000030;
  border-radius: 8px;
  width: 420px;
  position: relative;
  display: flex;
  flex-direction: column;

  & > header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 10px 0px 0px 0px;
    flex-grow: 0;

    button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: none;
      color: ${colors.text.tertiary};
      padding: 0;
      margin: 0;
    }
  }

  & > main {
    flex-grow: 1;
    height: 100%;
    overflow-y: hidden;
  }

  .help-content {
    h1 {
      color: ${colors.text.primary};
      font-size: 1.5rem;
      margin: 0;
      padding: 0;
    }

    main {
      overflow-y: auto;
    }
  }
`;
