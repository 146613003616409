import { Footer, ModalContainer } from "../export/ModalContainer";
import { ModalEnum, closeModal, lockModal, unlockModal } from "../model/modals";
import { getSyncStats } from "../model/sync/SyncStats";
import { Secondary } from "../share/Secondary";
import { Spinner } from "../utils/Spinner";
import { useLogoutAllTabs } from "./Logout";

export default function UnsyncedLogoutModalContent() {
  const { logoutAllTabs, isLoggingOut } = useLogoutAllTabs();

  const numberOfUnsyncedNotes = getSyncStats().sw?.dirtyNoteIds.size ?? 0;

  const handleConfirm = async () => {
    lockModal(ModalEnum.UNSYNCED_LOGOUT);
    await logoutAllTabs(true);
    unlockModal(ModalEnum.UNSYNCED_LOGOUT);
  };

  return (
    <ModalContainer type={ModalEnum.UNSYNCED_LOGOUT}>
      <div>
        <h1>Logout with unsynced data?</h1>
        <p>
          You have made changes to {numberOfUnsyncedNotes || "your"} notes that have not been synced. Logging out now
          will lose these changes. Are you sure you want to log out?
        </p>
        <Footer>
          {isLoggingOut ? (
            <div style={{ display: "flex", justifyContent: "center", width: 100 }}>
              <Spinner style={{ width: 30, height: 30 }} />
            </div>
          ) : (
            <Secondary onClick={handleConfirm}>Confirm</Secondary>
          )}
          <Secondary onClick={() => closeModal(ModalEnum.UNSYNCED_LOGOUT)} disabled={isLoggingOut}>
            Cancel
          </Secondary>
        </Footer>
      </div>
    </ModalContainer>
  );
}
